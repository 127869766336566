import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MissionHistoryService {


 // private apiUrl = 'http://localhost:9067/api/missionsHistory';
 // private missionsUrl = 'http://localhost:9067/api/missions';
 // private userUrl = 'http://localhost:9067/api/user';

  private apiUrl = environment.APP_API_URL +"/api/missionsHistory";
   private missionsUrl = environment.APP_API_URL +"/api/missions"; 
  private userUrl = environment.APP_API_URL +"/api/user";
  
  constructor(private http: HttpClient) { }

  getMissionHistory(): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl);
  }

    // Méthode pour obtenir toutes les missions
    getMissions(): Observable<any[]> {
      return this.http.get<any[]>(this.missionsUrl);
    }

      // Méthode pour obtenir les détails du chauffeur
  getChauffeur(id: number): Observable<any> {
    return this.http.get<any>(`${this.userUrl}/${id}`);
  }

}
