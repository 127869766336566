<div class="chat-container">
  <!-- Zone principale pour afficher les messages -->
  <div class="chat-main">
    <div class="chat-header">
      <h2>Discussion</h2>
    </div>
    
    <!-- Liste des messages -->
    <div class="chat-messages">
      <div *ngFor="let message of messages" 
           [ngClass]="{'sent': message.sender.id === currentUser.id, 'received': message.sender.id !== currentUser.id}" 
           class="message">
        <!-- Affichage de l'image de l'utilisateur -->
        <img [src]="message.sender?.image?.picByte ? 'data:image/jpeg;base64,' + message.sender.image.picByte : '/assets/profile-avatar.png'" 
             alt="Profile Picture" class="user-avatar">
        
        <p><strong>{{ message.sender.firstName }} {{message.sender.lastName}}:</strong> {{ message.content }}</p>
        <span class="message-time">{{ message.timestamp | date:'shortTime' }}</span>
      </div>
    </div>
    
    <!-- Input pour taper et envoyer un nouveau message -->
    <div class="chat-input">
      <input type="text" [(ngModel)]="newMessage" placeholder="Tapez un message..." (keyup.enter)="sendMessage()">
      <button class="send-button" (click)="sendMessage()">Envoyer</button>
    </div>
  </div>

  <!-- Message indiquant qu'il n'y a pas de messages -->
  <div *ngIf="messages.length === 0" class="no-messages">
    <p>Aucun message pour l'instant. Démarrez une conversation!</p>
  </div>
</div>
