import { Component, OnInit } from '@angular/core';
import { ConfirmDevisService } from 'src/app/Services/confirm-devis.service';

@Component({
  selector: 'app-confirm-devis',
  templateUrl: './confirm-devis.component.html',
  styleUrls: ['./confirm-devis.component.css']
})
export class ConfirmDevisComponent implements OnInit {
  confirmDevisList: any[] = [];
  userId: string | null = null;

  constructor(private confirmDevisService: ConfirmDevisService) { }

  ngOnInit(): void {
    this.userId = this.isUserAuthenticated();
    this.fetchConfirmDevis();
  }

  fetchConfirmDevis() {
    this.confirmDevisService.getConfirmDevis().subscribe(
      (data: any[]) => {
        this.confirmDevisList = data.filter(item => item.user.id.toString() === this.userId);
        console.log('Confirm Devis:', this.confirmDevisList);
      },
      error => {
        console.error('Erreur lors de la récupération des confirmations de devis:', error);
      }
    );
  }

  isUserAuthenticated(): string | null {
    return localStorage.getItem('idUser');
  } 
}
