import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DevisService } from 'src/app/Services/devis.service';
import { ReponseDevisService } from 'src/app/Services/reponse-devis.service';
import { UserService } from 'src/app/Services/user-service.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-add-response-devis',
  templateUrl: './add-response-devis.component.html',
  styleUrls: ['./add-response-devis.component.css']
})
export class AddResponseDevisComponent implements OnInit {
  reponseDevisForm: FormGroup;
  idDevis!: number;
  devis: any;
  transporteur: any; 
  errorMessage: string | null = null;
  idTransporteur: any;

  estimationDevisForm: FormGroup;
  estimation: number | null = null;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private devisService: DevisService,
    private reponseDevisService: ReponseDevisService,
    private userService: UserService
  ) {
    this.reponseDevisForm = this.formBuilder.group({
      prix: ['', [Validators.required, Validators.min(0)]]
    });

    this.estimationDevisForm = this.formBuilder.group({
      distance: ['', [Validators.required, Validators.min(0)]],
      prixKgKm: ['', [Validators.required, Validators.min(0)]]
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.idDevis = +params['id'];
      this.devisService.getDevisById(this.idDevis).subscribe(
        (data: any) => {
          this.devis = data;
          console.log('Devis récupéré:', this.devis);
          this.errorMessage = null;
        },
        (error) => {
          this.devis = null;
          this.errorMessage = 'Devis non trouvé';
        }
      );
    });

    this.idTransporteur = localStorage.getItem('idUser');
    console.log('Transporteur récupéré:', this.idTransporteur);

    if (this.idTransporteur) {
      this.userService.getUserById(this.idTransporteur).subscribe(
        (data: any) => {
          this.transporteur = data;
          console.log('Transporteur récupéré:', this.transporteur);
          this.errorMessage = null;
        },
        (error) => {
          this.transporteur = null;
          this.errorMessage = 'Transporteur non trouvé';
        }
      );
    }
  }

  onSubmit(): void {
    if (this.reponseDevisForm.valid && this.devis && this.transporteur) {
      const reponseDevis = {
        prix: this.reponseDevisForm.value.prix, 
        devis: this.devis,
        user: this.transporteur,
      };
  
      this.reponseDevisService.createReponseDevis(reponseDevis).subscribe(
        response => {
          Swal.fire('Succès', 'Réponse au devis enregistrée avec succès!', 'success');
        },
        error => {
          Swal.fire('Erreur', 'Erreur lors de l\'enregistrement de la réponse au devis.', 'error');
        }
      );
    } else {
      Swal.fire('Erreur', 'Formulaire invalide ou données manquantes', 'error');
    }
  }

  get prix() {
    return this.reponseDevisForm.get('prix');
  }

  estimerDevis() {
    if (this.estimationDevisForm.valid) {
      const distance = this.estimationDevisForm.get('distance')?.value;
      const prixKgKm = this.estimationDevisForm.get('prixKgKm')?.value;
      this.estimation = distance * prixKgKm;
    } else {
      Swal.fire('Erreur', 'Veuillez remplir tous les champs obligatoires.', 'error');
    }
  }
}
