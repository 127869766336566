

import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/Services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  loginForm: FormGroup;
  errorMessage: string | undefined;

  constructor(private fb: FormBuilder, private authService: AuthService, private router: Router) {
    this.loginForm = this.fb.group({
      userName: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  onSubmit() {
    if (this.loginForm.valid) {
      const { userName, password } = this.loginForm.value;
      this.authService.login(userName, password).subscribe(
        response => {
          // Redirection vers la page d'accueil en cas de succès
          this.router.navigate(['/home-page']);
        },
        error => {
          // Afficher un message d'erreur avec SweetAlert2
          Swal.fire({
            icon: 'error',
            title: 'Erreur de connexion',
            text: 'Nom d\'utilisateur ou mot de passe incorrect. Veuillez réessayer.'
          });
        }
      );
    }
  }
 
  }

