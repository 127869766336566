import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ResetPasswordService } from 'src/app/Services/reset-password.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm: FormGroup;
  message: string = '';

  constructor(private fb: FormBuilder, private resetPasswordService: ResetPasswordService) {
    this.resetPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  onSubmit() {
    if (this.resetPasswordForm.valid) {
      const email = this.resetPasswordForm.get('email')?.value;
      this.resetPasswordService.resetPassword(email).subscribe(
        response => {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response // Utilisez la réponse reçue du backend comme message de succès
          });
        }
      );
    }
  }

  ngOnInit(): void {
  }

}
