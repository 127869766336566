import { Component, OnInit } from '@angular/core';
import { ChauffeurService } from 'src/app/Services/chauffeur.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-chouffeur-list',
  templateUrl: './chouffeur-list.component.html',
  styleUrls: ['./chouffeur-list.component.css']
})
export class ChouffeurListComponent implements OnInit {

  users: any[];
  page: number = 1;
  totalLength: number = 0;
  searchText='';

  constructor(private chauffeurService: ChauffeurService) {
    this.users = [];
  }

  ngOnInit(): void {
    this.fetchUsers();
  }

  fetchUsers(): void {
    // Récupérer transporteurId depuis localStorage
    const transporteurIdString = localStorage.getItem('idUser');
  
    // Convertir transporteurId en nombre, si transporteurIdString n'est pas null
    const transporteurIdNumber = transporteurIdString ? parseInt(transporteurIdString, 10) : null;
  
    this.chauffeurService.getUsers().subscribe(data => {
      // Filtrer les utilisateurs par rôle "Chauffeur" et par transporteurId
      this.users = data.filter(user => 
        user.role === 'Chauffeur' && user.transporteurId === transporteurIdNumber 
      );
      this.totalLength = this.users.length;
    });
  }
  
  

  deleteUser(id: string): void {
    // Utilisation de SweetAlert2 pour la confirmation de suppression
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this user?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.isConfirmed) {
        // Appel de la méthode de suppression dans le service après confirmation
        this.chauffeurService.deleteUser(id).subscribe(() => {
          // Met à jour la liste des utilisateurs après suppression
          this.users = this.users.filter(user => user.id !== id);
          Swal.fire('Deleted!', 'User has been deleted.', 'success');
        }, error => {
          console.error('Error deleting user:', error);
          Swal.fire('Error!', 'Failed to delete user.', 'error');
        });
      }
    });
  }
}
