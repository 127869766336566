import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { VehiculeService } from 'src/app/Services/vehicule.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-vehicules',
  templateUrl: './vehicules.component.html',
  styleUrls: ['./vehicules.component.css']
})
export class VehiculesComponent implements OnInit {
  retrievedImage: any;
  vehicules: any[] = [];
  vehiculeForm: FormGroup;
  selectedFile!: File;
  searchText='';

  page: number = 1;
  totalLength: number = 0;

  userId: string | null = null;
  totalVehicules: number = 0; // Nombre total de véhicules
  availableVehicules: number = 0; // Nombre de véhicules disponibles
  unavailableVehicules: number = 0; // Nombre de véhicules non disponibles
  selectedStatus = '';
  constructor(
    private vehiculeService: VehiculeService,
    private fb: FormBuilder
  ) {
    this.vehiculeForm = this.fb.group({
      type: [''],
      immatriculation: [''],
      statut: [''],
      marque: [''],
      modele: [''],
      annee: [''],
      capacite: [''],
      kilometrage: [''],
      typeCarburant: [''],
      Categorie: [''],
      visiteTechnique: [''], // Nouveau champ
      expirationAssurance: [''], // Nouveau champ
      expirationPermisCirculation: [''] // Nouveau champ
    });    
  }

  ngOnInit(): void {
    this.getVehicules();
    this.userId = this.isUserAuthenticated();
  }

  getVehicules(): void {
    this.vehiculeService.getVehicules().subscribe(
      (data: any[]) => {
        if (this.userId !== null) {
          // Convertir userId en entier seulement s'il n'est pas null
          const userIdNumber = parseInt(this.userId, 10);
          this.vehicules = data.filter(item => item.transporteurId === userIdNumber);
        } else {
          // Gérer le cas où userId est null, par exemple en assignant un tableau vide ou en affichant un message
          this.vehicules = [];
        }

             // Calculer le nombre total de véhicules
             this.totalVehicules = this.vehicules.length;

             // Calculer le nombre de véhicules disponibles et non disponibles
             this.availableVehicules = this.vehicules.filter(vehicule => vehicule.statut === 'Disponible').length;
             this.unavailableVehicules = this.totalVehicules - this.availableVehicules;
      },
      error => {
        console.error('Erreur lors de la récupération des données :', error);
      }
    );
  }
  
  
  addVehicule(): void {
    Swal.fire({
      title: '<strong>Ajouter un véhicule</strong>',
      html: `
        <div style="display: flex; flex-direction: column; gap: 15px;">
          <input id="type" class="swal2-input" placeholder="Type" style="width: 90%;">
          <input id="immatriculation" class="swal2-input" placeholder="Immatriculation" style="width: 90%;">
          <select id="statut" class="swal2-select" style="width: 90%;">
            <option value="" disabled selected>Statut</option>
            <option value="Disponible">Disponible</option>
            <option value="Non disponible">Non Disponible</option>
            <option value="En panne">En panne</option>
            <option value="Document non valable">Document non valable</option>
            <option value="En maintenance">En maintenance</option>
            <option value="En cours de réparation">En cours de réparation</option>
            <option value="Hors service">Hors service</option>
            <option value="En transit">En transit</option>
            <option value="En attente d'inspection">En attente d'inspection</option>
          </select>
          <input id="marque" class="swal2-input" placeholder="Marque" style="width: 90%;">
          <input id="modele" class="swal2-input" placeholder="Modèle" style="width: 90%;">
          <input id="annee" class="swal2-input" placeholder="Année" type="number" style="width: 90%;">
          <input id="capacite" class="swal2-input" placeholder="Capacité" type="number" style="width: 90%;">
          <input id="kilometrage" class="swal2-input" placeholder="Kilométrage" type="number" style="width: 90%;">
          <input id="typeCarburant" class="swal2-input" placeholder="Type de Carburant" style="width: 90%;">
       <input id="visiteTechnique" class="swal2-input" placeholder="Visite Technique" type="date" style="width: 90%;">
      <input id="expirationAssurance" class="swal2-input" placeholder="Expiration Assurance" type="date" style="width: 90%;">
      <input id="expirationPermisCirculation" class="swal2-input" placeholder="Expiration Permis de Circulation" type="date" style="width: 90%;">
      
          <select id="categorie" class="swal2-select" style="width: 90%;">
            <option value="">Sélectionner le type de véhicule</option>
            <optgroup label="Camions légers">
              <option value="fourgonnettes">Fourgonnettes</option>
              <option value="pick_up">Pick-up</option>
              <option value="camionnette_ridelles">Camionnette à ridelles</option>
            </optgroup>
            <optgroup label="Camions moyens">
              <option value="camion_porteur">Camion porteur</option>
              <option value="camion_citerne">Camion-citerne</option>
              <option value="camion_benne">Camion-benne</option>
              <option value="camion_plateau">Camion plateau</option>
            </optgroup>
            <optgroup label="Camions lourds">
              <option value="camion_grue">Camion-grue</option>
              <option value="camion_frigorifique">Camion frigorifique</option>
              <option value="camion_benne_ordures">Camion-benne à ordures</option>
              <option value="camion_remorque">Camion-remorque</option>
            </optgroup>
            <optgroup label="Camions articulés">
              <option value="semi_remorque">Semi-remorque</option>
              <option value="camion_citerne_articule">Camion-citerne articulé</option>
              <option value="camion_transport_conteneurs">Camion de transport de conteneurs</option>
            </optgroup>
            <optgroup label="Camions spécialisés">
              <option value="camion_demenagement">Camion de déménagement</option>
              <option value="camion_pompiers">Camion de pompiers</option>
              <option value="camion_maintenance">Camion de maintenance routière</option>
              <option value="camion_transport_vehicules">Camion de transport de véhicules</option>
              <option value="camion_chantier">Camion de chantier</option>
            </optgroup>
            <optgroup label="Camions de transport spécialisé">
              <option value="camion_transport_betail">Camion de transport de bétail</option>
              <option value="camion_transport_materiel_lourd">Camion de transport de matériel lourd</option>
              <option value="camion_transport_produits_dangereux">Camion de transport de produits dangereux</option>
            </optgroup>
          </select>
          <input id="image" class="swal2-input" type="file" accept="image/*" style="width: 90%;">
        </div>
      `,
      focusConfirm: false,
      width: '60%',
      customClass: {
        popup: 'swal2-modern-popup',
        confirmButton: 'swal2-confirm-button',
        cancelButton: 'swal2-cancel-button'
      },
      showCancelButton: true,
      confirmButtonText: 'Ajouter',
      cancelButtonText: 'Annuler',
      preConfirm: () => {
        const typeElement = document.getElementById('type') as HTMLInputElement;
        const immatriculationElement = document.getElementById('immatriculation') as HTMLInputElement;
        const statutElement = document.getElementById('statut') as HTMLSelectElement;
        const marqueElement = document.getElementById('marque') as HTMLInputElement;
        const modeleElement = document.getElementById('modele') as HTMLInputElement;
        const anneeElement = document.getElementById('annee') as HTMLInputElement;
        const capaciteElement = document.getElementById('capacite') as HTMLInputElement;
        const kilometrageElement = document.getElementById('kilometrage') as HTMLInputElement;
        const typeCarburantElement = document.getElementById('typeCarburant') as HTMLInputElement;
        const categorieElement = document.getElementById('categorie') as HTMLSelectElement;
        const visiteTechniqueElement = document.getElementById('visiteTechnique') as HTMLInputElement;
        const expirationAssuranceElement = document.getElementById('expirationAssurance') as HTMLInputElement;
        const expirationPermisElement = document.getElementById('expirationPermisCirculation') as HTMLInputElement;    
        const imageElement = document.getElementById('image') as HTMLInputElement;
  
        if (!typeElement.value || !immatriculationElement.value || !statutElement.value ||
            !marqueElement.value || !modeleElement.value || !anneeElement.value ||
            !capaciteElement.value || !kilometrageElement.value || !typeCarburantElement.value ||
            !categorieElement.value || !imageElement.files?.length || !visiteTechniqueElement.value 
            || !expirationAssuranceElement.value || !expirationPermisElement.value) {
          Swal.showValidationMessage('Tous les champs sont requis.');
          return null;
        }
  
        const vehicule = {
          type: typeElement.value,
          immatriculation: immatriculationElement.value,
          statut: statutElement.value,
          marque: marqueElement.value,
          modele: modeleElement.value,
          annee: +anneeElement.value,
          capacite: +capaciteElement.value,
          kilometrage: +kilometrageElement.value,
          typeCarburant: typeCarburantElement.value,
          categorie: categorieElement.value,
          visiteTechnique: visiteTechniqueElement.value, 
          expirationAssurance: expirationAssuranceElement.value,
          expirationPermisCirculation: expirationPermisElement.value,
          transporteurId: window.localStorage.getItem('idUser')
        };
  
        const image = imageElement.files[0];
  
        return { vehicule, image };
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const { vehicule, image } = result.value;
        this.vehiculeService.addVehicule(vehicule, image).subscribe(
          response => {
            this.getVehicules(); // Rafraîchir la liste après l'ajout
            Swal.fire('Succès', 'Véhicule ajouté avec succès', 'success');
          },
          error => {
            Swal.fire('Erreur', 'Erreur lors de l\'ajout du véhicule. Veuillez réessayer.', 'error');
          }
        );
      }
    });
  
    // Ajouter une classe personnalisée au select après le rendu du popup
    setTimeout(() => {
      const selects = document.querySelectorAll('.swal2-select');
      selects.forEach(select => select.classList.add('styled-select'));
    }, 0);
  }
  
  modifierVehicule(vehicule: any): void {
    Swal.fire({
      title: '<strong>Modifier un véhicule</strong>',
      html: `
        <div style="display: flex; flex-direction: column; gap: 15px;">
          <input id="type" class="swal2-input" placeholder="Type" value="${vehicule.type}" style="width: 90%;">
          <input id="immatriculation" class="swal2-input" placeholder="Immatriculation" value="${vehicule.immatriculation}" style="width: 90%;">
          <select id="statut" class="swal2-select" style="width: 90%;">
            <option value="" disabled>Statut</option>
            <option value="Disponible" ${vehicule.statut === 'Disponible' ? 'selected' : ''}>Disponible</option>
            <option value="Non disponible" ${vehicule.statut === 'Non disponible' ? 'selected' : ''}>Non disponible</option>
            <option value="En panne" ${vehicule.statut === 'En panne' ? 'selected' : ''}>En panne</option>
            <!-- Add other statut options similarly -->
          </select>
          <input id="marque" class="swal2-input" placeholder="Marque" value="${vehicule.marque}" style="width: 90%;">
          <input id="modele" class="swal2-input" placeholder="Modèle" value="${vehicule.modele}" style="width: 90%;">
          <input id="annee" class="swal2-input" placeholder="Année" type="number" value="${vehicule.annee}" style="width: 90%;">
          <input id="capacite" class="swal2-input" placeholder="Capacité" type="number" value="${vehicule.capacite}" style="width: 90%;">
          <input id="kilometrage" class="swal2-input" placeholder="Kilométrage" type="number" value="${vehicule.kilometrage}" style="width: 90%;">
          <input id="typeCarburant" class="swal2-input" placeholder="Type de Carburant" value="${vehicule.typeCarburant}" style="width: 90%;">
          <input id="visiteTechnique" class="swal2-input" placeholder="Visite Technique" type="date" value="${vehicule.visiteTechnique}" style="width: 90%;">
          <input id="expirationAssurance" class="swal2-input" placeholder="Expiration Assurance" type="date" value="${vehicule.expirationAssurance}" style="width: 90%;">
          <input id="expirationPermisCirculation" class="swal2-input" placeholder="Expiration Permis de Circulation" type="date" value="${vehicule.expirationPermisCirculation}" style="width: 90%;">
      <select id="categorie" class="swal2-select" style="width: 90%;">
            <option value="">Sélectionner le type de véhicule</option>
            <optgroup label="Camions légers">
              <option value="fourgonnettes">Fourgonnettes</option>
              <option value="pick_up">Pick-up</option>
              <option value="camionnette_ridelles">Camionnette à ridelles</option>
            </optgroup>
            <optgroup label="Camions moyens">
              <option value="camion_porteur">Camion porteur</option>
              <option value="camion_citerne">Camion-citerne</option>
              <option value="camion_benne">Camion-benne</option>
              <option value="camion_plateau">Camion plateau</option>
            </optgroup>
            <optgroup label="Camions lourds">
              <option value="camion_grue">Camion-grue</option>
              <option value="camion_frigorifique">Camion frigorifique</option>
              <option value="camion_benne_ordures">Camion-benne à ordures</option>
              <option value="camion_remorque">Camion-remorque</option>
            </optgroup>
            <optgroup label="Camions articulés">
              <option value="semi_remorque">Semi-remorque</option>
              <option value="camion_citerne_articule">Camion-citerne articulé</option>
              <option value="camion_transport_conteneurs">Camion de transport de conteneurs</option>
            </optgroup>
            <optgroup label="Camions spécialisés">
              <option value="camion_demenagement">Camion de déménagement</option>
              <option value="camion_pompiers">Camion de pompiers</option>
              <option value="camion_maintenance">Camion de maintenance routière</option>
              <option value="camion_transport_vehicules">Camion de transport de véhicules</option>
              <option value="camion_chantier">Camion de chantier</option>
            </optgroup>
            <optgroup label="Camions de transport spécialisé">
              <option value="camion_transport_betail">Camion de transport de bétail</option>
              <option value="camion_transport_materiel_lourd">Camion de transport de matériel lourd</option>
              <option value="camion_transport_produits_dangereux">Camion de transport de produits dangereux</option>
            </optgroup>
          </select>
          <input id="image" class="swal2-input" type="file" accept="image/*" style="width: 90%;">
        </div>
      `,
      focusConfirm: false,
      width: '60%',
      customClass: {
        popup: 'swal2-modern-popup',
        confirmButton: 'swal2-confirm-button',
        cancelButton: 'swal2-cancel-button'
      },
      showCancelButton: true,
      confirmButtonText: 'Modifier',
      cancelButtonText: 'Annuler',
      preConfirm: () => {
        const typeElement = document.getElementById('type') as HTMLInputElement;
        const immatriculationElement = document.getElementById('immatriculation') as HTMLInputElement;
        const statutElement = document.getElementById('statut') as HTMLSelectElement;
        const marqueElement = document.getElementById('marque') as HTMLInputElement;
        const modeleElement = document.getElementById('modele') as HTMLInputElement;
        const anneeElement = document.getElementById('annee') as HTMLInputElement;
        const capaciteElement = document.getElementById('capacite') as HTMLInputElement;
        const kilometrageElement = document.getElementById('kilometrage') as HTMLInputElement;
        const typeCarburantElement = document.getElementById('typeCarburant') as HTMLInputElement;
        const categorieElement = document.getElementById('categorie') as HTMLSelectElement;
        const visiteTechniqueElement = document.getElementById('visiteTechnique') as HTMLInputElement;
        const expirationAssuranceElement = document.getElementById('expirationAssurance') as HTMLInputElement;
        const expirationPermisElement = document.getElementById('expirationPermisCirculation') as HTMLInputElement;
        const imageElement = document.getElementById('image') as HTMLInputElement;
  
        if (!typeElement.value || !immatriculationElement.value || !statutElement.value ||
            !marqueElement.value || !modeleElement.value || !anneeElement.value ||
            !capaciteElement.value || !kilometrageElement.value || !typeCarburantElement.value ||
            !categorieElement.value || !visiteTechniqueElement.value || 
            !expirationAssuranceElement.value || !expirationPermisElement.value) {
          Swal.showValidationMessage('Tous les champs sont requis.');
          return null;
        }
  
        const updatedVehicule = {
          type: typeElement.value,
          immatriculation: immatriculationElement.value,
          statut: statutElement.value,
          marque: marqueElement.value,
          modele: modeleElement.value,
          annee: +anneeElement.value,
          capacite: +capaciteElement.value,
          kilometrage: +kilometrageElement.value,
          typeCarburant: typeCarburantElement.value,
          categorie: categorieElement.value,
          visiteTechnique: visiteTechniqueElement.value,
          expirationAssurance: expirationAssuranceElement.value,
          expirationPermisCirculation: expirationPermisElement.value,
          transporteurId: vehicule.transporteurId // Assuming it's part of the object
        };
  
        const image = imageElement.files?.length ? imageElement.files[0] : null;
  
        return { updatedVehicule, image };
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const { updatedVehicule, image } = result.value;
        this.vehiculeService.updateVehicule(updatedVehicule, image).subscribe(
          response => {
            this.getVehicules(); // Refresh list after update
            Swal.fire('Succès', 'Véhicule modifié avec succès', 'success');
          },
          error => {
            Swal.fire('Erreur', 'Erreur lors de la modification du véhicule. Veuillez réessayer.', 'error');
          }
        );
      }
    });
  }
  
  
  deleteVehicule(id: number): void {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: "Vous ne pourrez pas annuler cette action!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprimer!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.vehiculeService.deleteVehicule(id).subscribe(
          response => {
            console.log(response);
            this.getVehicules(); // Rafraîchir la liste après la suppression
            Swal.fire('Supprimé!', 'Le véhicule a été supprimé.', 'success');
          },
          error => console.error(error)
        );
      }
    });
  }

  isUserAuthenticated(): string | null {
    return localStorage.getItem('idUser');
  } 

  filterByStatus(): void {
    this.vehiculeService.getVehicules().subscribe(
      (data: any[]) => {
        if (this.userId !== null) {
          const userIdNumber = parseInt(this.userId, 10);
          this.vehicules = data.filter(item => item.transporteurId === userIdNumber);
          
          // Filtrer en fonction du statut sélectionné
          if (this.selectedStatus === 'Disponible') {
            this.vehicules = this.vehicules.filter(vehicule => vehicule.statut === 'Disponible');
          } else if (this.selectedStatus === 'Non disponible') {
            this.vehicules = this.vehicules.filter(vehicule => vehicule.statut !== 'Disponible');
          }

          //this.updateStatistics();
        } else {
          this.vehicules = [];
        }
      },
      error => {
        console.error('Erreur lors de la récupération des données :', error);
      }
    );
  }

  isDatePast(dateString: string): boolean {
    const date = new Date(dateString);
    const currentDate = new Date();
    return date <= currentDate;
  }
}
