import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChauffeurService } from 'src/app/Services/chauffeur.service';
import { ImageUploadService } from 'src/app/Services/image-upload.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-details-chauffeur',
  templateUrl: './details-chauffeur.component.html',
  styleUrls: ['./details-chauffeur.component.css']
})
export class DetailsChauffeurComponent implements OnInit {
  userId!: number;
  imageNames: string[] = [];
  errorMessage!: string;
  chauffeur: any;
  userIdString!: string;
  constructor(private route: ActivatedRoute, private imageService: ImageUploadService, private chauffeurService: ChauffeurService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.userId = +params['id']; // Convertit 'id' en un nombre
      this.loadImageNames();
      this.userIdString = this.userId.toString();
      this.getChauffeurDetails(this.userIdString);
    });
  }

  getChauffeurDetails(id: string): void {
    this.chauffeurService.getUserById(id).subscribe(
      data => {
        this.chauffeur = data;
        console.log(this.chauffeur);
      },
      error => {
        console.error('There was an error!', error);
      }
    );
  }

  loadImageNames(): void {
    this.imageService.getImageNamesByUserId(this.userId)
      .subscribe(
        names => {
          this.imageNames = names;
        },
        error => {
          this.errorMessage = error.message;
        }
      );
  }

  openImageModal(imageName: string): void {
    this.imageService.getImage(imageName)
      .subscribe(
        res => {
          const base64Data = res.picByte;
          const imageUrl = 'data:image/jpeg;base64,' + base64Data;
          
          Swal.fire({
            title: 'Image Viewer',
            imageUrl: imageUrl,
            imageAlt: imageName,
            showCloseButton: true,
            showConfirmButton: false,
            customClass: {
              image: 'custom-size-modal' // Classe CSS pour personnaliser la taille du modal
            },
            width: '80%' // Largeur du modal
          });
        },
        error => {
          console.error('Error retrieving image:', error);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Failed to retrieve image!',
            footer: 'Please try again later.'
          });
        }
      );
  }

}
