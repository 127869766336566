import { Component, OnInit } from '@angular/core';
import { DevisService } from 'src/app/Services/devis.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-list-demande-devis',
  templateUrl: './list-demande-devis.component.html',
  styleUrls: ['./list-demande-devis.component.css']
})
export class ListDemandeDevisComponent implements OnInit {

  devisList: any[] = [];
  searchText = '';
  page: number = 1;
  totalLength: number = 0;

  constructor(private devisService: DevisService) { }

  ngOnInit(): void {
    this.fetchDevis();
  }

  fetchDevis(): void {
    this.devisService.getDevis().subscribe(
      data => {
        const userId = this.isUserAuthenticated();
        if (userId) {
          this.devisList = data.filter(devis => devis.idsTransporteur.includes(userId));
        } else {
          this.devisList = [];
        }
        this.totalLength = this.devisList.length;
      },
      error => {
        console.error('Error fetching devis', error);
      }
    );
  }

  showDetails(devis: any): void {
    const formattedDate = new Date(devis.dateLivraisonSouhaitee).toLocaleDateString();
    const createdAtDate = new Date(devis.createdAt).toLocaleString();

    Swal.fire({
      title: 'Détails du Devis',
      html: `
        <strong>Nom Client:</strong> ${devis.nomClient}<br>
        <strong>Adresse Client:</strong> ${devis.adresseClient}<br>
        <strong>Email Client:</strong> ${devis.emailClient}<br>
        <strong>Téléphone Client:</strong> ${devis.telephoneClient}<br>
        <strong>Ville Départ:</strong> ${devis.villeDepart}<br>
        <strong>Ville Arrivée:</strong> ${devis.villeArrivee}<br>
        <strong>Date Livraison Souhaitée:</strong> ${formattedDate}<br>
        <strong>Description Cargaison:</strong> ${devis.descriptionCargaison}<br>
        <strong>Type Cargaison:</strong> ${devis.typeCargaison}<br>
        <strong>Poids Cargaison:</strong> ${devis.poidsCargaison} kg<br>
        <strong>Volume Cargaison:</strong> ${devis.volumeCargaison} m³<br>
        <strong>Remarques:</strong> ${devis.remarques}<br>
        <strong>Statut:</strong> ${devis.statut}<br>
        <strong>Créé le:</strong> ${createdAtDate}
      `,
      showCloseButton: true,
      showConfirmButton: false
    });
  }

  isUserAuthenticated(): string | null {
    return localStorage.getItem('idUser');
  }

}
