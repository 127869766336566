<!-- mission-chauffeur.component.html -->
<div class="container">
  <!-- Search and Sort Container -->
  <div class="search-sort-container">
    <input 
      type="text" 
      [(ngModel)]="searchText" 
      class="form-control search-input" 
      placeholder="Rechercher..." 
    />
    <div class="sort-buttons">
      <button class="btn sort-btn" (click)="sortByDateDebut()">
        <i class="fa fa-calendar-day me-2"></i>Trier par Date de Début
      </button>
      <button class="btn sort-btn" (click)="sortByDateFin()">
        <i class="fa fa-calendar-week me-2"></i>Trier par Date de Fin
      </button>
    </div>
  </div>
  <div class="col text-end">
    Total d'éléments : <span class="badge">{{ missions.length }}</span>
  </div>
  <br>
  <!-- Table Container -->
  <div class="table-container">
    <table class="table table-responsive">
      <thead>
        <tr>
          <th>Description</th>
          <th>Date de Début</th>
          <th>Date de Fin</th>
          <th class="status-column">Statut</th>
          <th>Matricule Véhicule</th>
          <th>Nom Client</th>
          <th>Adresse Client</th>
          <th>Téléphone Client</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let mission of missions | paginate: { itemsPerPage: 6, currentPage: page, totalItems: totalLength } | filter:searchText">
          <td>{{ mission.description }}</td>
          <td>{{ mission.dateDebut | date:'shortDate' }}</td>
          <td>{{ mission.dateFin | date:'shortDate' }}</td>
          <td>
            <span [ngClass]="getStatusClass(mission.statut)" class="badge-status">
              {{ mission.statut }}
            </span>
          </td>
          <td>{{ mission.vehicule.immatriculation }}</td>
          <td>{{ mission.devis.nomClient }}</td>
          <td>{{ mission.devis.adresseClient }}</td>
          <td>{{ mission.devis.telephoneClient }}</td>
          <td>
            <button class="btn btn-edit" (click)="openStatusEditPopup(mission)">
              <i class="fa fa-pencil-square" aria-hidden="true"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Pagination Controls -->
    <div class="pagination-container">
      <pagination-controls (pageChange)="page = $event"></pagination-controls>
    </div>
  </div>
</div>
