<div class="form-container">
    <h1 style="margin-top:2rem; text-align: center; font-family: 'Open Sans',sans-serif; font-weight: 900; color: #002e51;">Nouvelle mission</h1>
<hr>
    <form [formGroup]="missionForm" (ngSubmit)="onSubmit()">
      
      <div class="row">
        <div class="col-md-6 form-group mb-3">
          <label for="villeDepart">Ville Départ:</label>
          <select id="villeDepart" formControlName="villeDepart" class="form-control">
            <option value="" disabled selected>Code postal, Ville</option>
            <option *ngFor="let ville of villesList" [value]="ville.codePostal + ', ' + ville.ville">
                {{ ville.codePostal }}, {{ ville.ville }} 
            </option>
          </select>
        </div>
        <div class="col-md-6 form-group mb-3">
          <label for="villeArrivee">Ville Arrivée:</label>
          <select id="villeArrivee" formControlName="villeArrivee" class="form-control">
            <option value="" disabled selected>Code postal, Ville</option>
            <option *ngFor="let ville of villesList" [value]="ville.codePostal + ', ' + ville.ville">
                {{ ville.codePostal }}, {{ ville.ville }} 
            </option>
          </select>
        </div>
      </div>
  
      <div class="row">
        <div class="col-md-6 form-group">
          <label for="nomClient">Nom Client:</label>
          <input id="nomClient" formControlName="nomClient" type="text" class="form-control">
        </div>
        <div class="col-md-6 form-group">
          <label for="telephoneClient">Téléphone Client:</label>
          <input id="telephoneClient" formControlName="telephoneClient" type="text" class="form-control">
        </div>
      </div>
  
      <div class="row">
        <div class="col-md-6 form-group">
          <label for="emailClient">E-mail Client:</label>
          <input id="emailClient" formControlName="emailClient" type="text" class="form-control">
        </div>
        <div class="col-md-6 form-group">
          <label for="dateDebut">Date Début:</label>
          <input id="dateDebut" formControlName="dateDebut" type="datetime-local" class="form-control">
        </div>
      </div>
  
      <div class="row">
        <div class="col-md-6 form-group">
          <label for="dateFin">Date Fin:</label>
          <input id="dateFin" formControlName="dateFin" type="datetime-local" class="form-control">
        </div>
        <div class="col-md-6 form-group">
          <label for="description">Description:</label>
          <input id="description" formControlName="description" type="text" class="form-control">
        </div>
      </div>
  
      <div class="row">
        <div class="col-md-6 form-group">
          <label for="vehiculeId">Véhicule:</label>
          <select id="vehiculeId" formControlName="vehiculeId" class="form-control">
            <option *ngFor="let vehicule of vehicules" [value]="vehicule.id">
              {{ vehicule.type }} - {{ vehicule.immatriculation }}
            </option>
          </select>
        </div>
        <div class="col-md-6 form-group">
          <label for="chauffeurId">Chauffeur:</label>
          <select id="chauffeurId" formControlName="chauffeurId" class="form-control">
            <option *ngFor="let chauffeur of chauffeurs" [value]="chauffeur.id">
              {{ chauffeur.firstName }} - {{ chauffeur.lastName }}
            </option>
          </select>
        </div>
      </div>
  
      <div class="row">
        <div class="col-md-12">
          <button type="submit" class="btn btn-primary" [disabled]="!missionForm.valid">Create Mission</button>
        </div>
      </div>
  
    </form>
  </div>
  