import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DevisService } from 'src/app/Services/devis.service';

@Component({
  selector: 'app-reponse-devis-code',
  templateUrl: './reponse-devis-code.component.html',
  styleUrls: ['./reponse-devis-code.component.css']
})
export class ReponseDevisCodeComponent implements OnInit {

  codeReponseDevis: string = '';
  devis: any | null = null;
  errorMessage: string | null = null;

  constructor(
    private route: ActivatedRoute,
    private devisService: DevisService
  ) {}

  ngOnInit(): void {
    // Récupérer le code de réponse de devis depuis les paramètres d'URL
    this.route.params.subscribe(params => {
      this.codeReponseDevis = params['codeDevis'];
      this.getDevis(); // Appel de la méthode pour récupérer les détails du devis
    });
  }

  getDevis() {
    this.devisService.getDevisByCodeReponseDevis(this.codeReponseDevis).subscribe(
      (data: any) => {
        this.devis = data;
        this.errorMessage = null;
      },
      (error) => {
        this.devis = null;
        this.errorMessage = 'Devis non trouvé';
      }
    );
  }

}
