<div class="custom-box custom-box-small">
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid d-flex align-items-center">
      <a class="navbar-brand" href="">
        <img src="/assets/logo_transp.png" alt="Logo" style="height: 6rem; width: 19rem;">
      </a>

      <div class="d-flex-align">
        <ul class="navbar-nav">
          
          <li class="nav-item">
            <a *ngIf="!isUserAuthenticated()" class="nav-link" href="/guide">Guide d'utilisation</a>
          </li>
          <li class="nav-item">
            <a *ngIf="!isUserAuthenticated()" class="nav-link" href="/comment-ca-marche">Comment ça marche ?</a>
          </li>
          <li class="nav-item">
            <a *ngIf="!isUserAuthenticated()" class="nav-link" href="/devis-client">Demander devis</a>
          </li>
          <li class="nav-item">
            <a *ngIf="!isUserAuthenticated()" class="nav-link" href="/reponse-devis">Réponse devis</a>
          </li>
          <li class="nav-item">
            <a *ngIf="!isUserAuthenticated()" class="nav-link" href="/transporteurs">Annuaire des transporteurs</a>
          </li>
          <li class="nav-item">
            <a *ngIf="!isUserAuthenticated()" class="nav-link" href="/contact">Contact</a>
          </li>
          <li class="nav-item">
            <a *ngIf="!isUserAuthenticated()" class="nav-link" href="/register">Inscription</a>
          </li>
          <li class="nav-item">
            <a *ngIf="!isUserAuthenticated()" class="nav-link" href="/login">Connexion</a>
          </li>
        </ul>
      </div>

      <form class="d-flex align-items-center" *ngIf="isUserAuthenticated()">
        <input class="form-control me-2" type="search" placeholder="Rechercher" aria-label="Search">
        <button class="btn btn-outline-primary" type="submit">Rechercher</button>
      </form>

      <a *ngIf="isUserAuthenticated()" class="logout-link" (click)="logout()">
        <i class="fa fa-sign-out"></i>
        <span>Déconnexion</span>
      </a>
    </div>
  </nav>
</div>
