<div class="container">
    <div *ngIf="confirmDevisList && confirmDevisList.length > 0">
      <h2 class="title">Liste des Confirmations de Devis</h2>
      <div *ngFor="let confirmDevis of confirmDevisList" class="card custom-card">
        <div class="card-body">
    
          <a class="btn btn-outline-secondary btn-sm float-right" [routerLink]="['/add-mission', confirmDevis.devis.id]"> Mission </a>
          <h3 class="card-title">Devis ID: {{ confirmDevis.devis.id }}</h3>
        
          <div class="devis-details">
            <p><strong>Date Demande:</strong> {{ confirmDevis.devis.dateDemande | date:'medium' }}</p>
            <p><strong>Statut:</strong> {{ confirmDevis.devis.statut }}</p>
            <p><strong>Ville Départ:</strong> {{ confirmDevis.devis.villeDepart }}</p>
            <p><strong>Ville Arrivée:</strong> {{ confirmDevis.devis.villeArrivee }}</p>
            <p><strong>Type Cargaison:</strong> {{ confirmDevis.devis.typeCargaison }}</p>
            <p><strong>Description Cargaison:</strong> {{ confirmDevis.devis.descriptionCargaison }}</p>
            <p><strong>Poids Cargaison:</strong> {{ confirmDevis.devis.poidsCargaison }} kg</p>
            <p><strong>Volume Cargaison:</strong> {{ confirmDevis.devis.volumeCargaison }} m³</p>
            <p><strong>Date Livraison Souhaitée:</strong> {{ confirmDevis.devis.dateLivraisonSouhaitee | date:'mediumDate' }}</p>
            <p><strong>Remarques:</strong> {{ confirmDevis.devis.remarques }}</p>
            <p><strong>Créé le:</strong> {{ confirmDevis.devis.createdAt | date:'medium' }}</p>
            <p><strong>Mis à jour le:</strong> {{ confirmDevis.devis.updatedAt | date:'medium' }}</p>
          </div>
          <h4 class="section-title">Client</h4>
          <div class="user-details">
            <p><strong>Nom Client:</strong> {{ confirmDevis.devis.nomClient }}</p>
            <p><strong>Adresse Client:</strong> {{ confirmDevis.devis.adresseClient }}</p>
            <p><strong>Téléphone Client:</strong> {{ confirmDevis.devis.telephoneClient }}</p>
            <p><strong>Email Client:</strong> {{ confirmDevis.devis.emailClient }}</p>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="confirmDevisList && confirmDevisList.length === 0" class="no-confirmation">
      <p>Aucune confirmation de devis trouvée.</p>
    </div>
  </div>
  