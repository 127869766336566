import { Component, OnInit } from '@angular/core';



@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {
  totalDeliveries: number = 120;
  ongoingDeliveries: number = 30;
  completedDeliveries: number = 80;
  totalVehicles: number = 45;
  vehiclesInService: number = 40;
  vehiclesInMaintenance: number = 5;



  chauffeurPerformance: {
    successfulDeliveries: number;
    delays: number;
    complaints: number;
  };

  reportsGenerated: number;
  customerSatisfaction: string;
  feedbackCount: number;


  upcomingDeliveries = [
    { title: 'Livraison à Sousse', date: '2024-09-01' },
    { title: 'Livraison à Sfax', date: '2024-09-03' },
    { title: 'Livraison à Tabarka', date: '2024-09-05' }
  ];

  recentNotifications = [
    { message: 'Nouvelle livraison prévue pour demain.', date: '2024-09-02' },
    { message: 'Véhicule en maintenance terminé.', date: '2024-09-01' },
    { message: 'Nouvelle commande reçue.', date: '2024-09-01' }
  ];

  pendingTasks = [
    { title: 'Vérifier les stocks', priority: 'Haute', deadline: '2024-09-04' },
    { title: 'Planifier l’entretien', priority: 'Moyenne', deadline: '2024-09-06' },
    { title: 'Envoyer les factures', priority: 'Basse', deadline: '2024-09-07' }
  ];
  chauffeursList = [
    {
      image: '/assets/profile-avatar.png',
      nom: 'Ali',
      prenom: 'Tounssi',
      statut: 'Actif'
    },
    {
      image: '/assets/profile-avatar.png',
      nom: 'Slim',
      prenom: 'Ben ahmed',
      statut: 'En congé'
    },
    {
      image: '/assets/profile-avatar.png',
      nom: 'Sabri',
      prenom: 'Kraiem',
      statut: 'Actif'
    }
    ,
    {
      image: '/assets/profile-avatar.png',
      nom: 'Lassed',
      prenom: 'Ben ali',
      statut: 'Actif'
    }
    ,
    {
      image: '/assets/profile-avatar.png',
      nom: 'Mohamed',
      prenom: 'Gessmi',
      statut: 'En congé'
    }
    // Ajoutez d'autres chauffeurs ici
  ];
  constructor() { 
     // Initialisation des valeurs par défaut
     this.chauffeurPerformance = {
      successfulDeliveries: 0,
      delays: 0,
      complaints: 0
    };
    this.reportsGenerated = 0;
    this.customerSatisfaction = 'N/A';
    this.feedbackCount = 0;
  }

  ngOnInit(): void {
  // Charger les données nécessaires ici

    // Exemple de données simulées
    this.chauffeurPerformance = {
      successfulDeliveries: 120,
      delays: 15,
      complaints: 5
    };

    this.reportsGenerated = 8;
    this.customerSatisfaction = '85%';
    this.feedbackCount = 42;
  }

  generateReport(): void {
    // Logique pour générer un rapport
    alert('Génération d\'un rapport...');
    // Ajoutez ici la logique nécessaire pour générer le rapport
  }

  viewCustomerFeedback(): void {
    // Logique pour visualiser les retours des clients
    console.log('Visualisation des retours clients...');
    // Ajoutez ici la logique nécessaire pour afficher les retours clients
  }
    

}
