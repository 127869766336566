import { Component, OnInit } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {
  email: string = '';
  password: string = '';

  constructor(

  ) { }

  ngOnInit(): void {

}

onSubmit() {
  console.log('Email:', this.email);
  console.log('Password:', this.password);
 
}

}