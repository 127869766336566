<footer class="footer-section">
    <div class="container">
        <div class="footer-cta pt-5 pb-5">
            <div class="row">
                <div class="col-xl-4 col-md-4 mb-30">
                    <div class="single-cta">
                        <i class="fa fa-map-marker"></i>
                        <div class="cta-text">
                            <h4>Nous trouver</h4>
                            <span>Rue Apollo XI, Tunis 1080</span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-md-4 mb-30">
                    <div class="single-cta">
                        <i class="fa fa-phone"></i>
                        <div class="cta-text">
                            <h4>Nous appeler</h4>
                            <span>(+216) 98 35 93 58</span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-md-4 mb-30">
                    <div class="single-cta">
                        <i class="fa fa-envelope-o"></i>
                        <div class="cta-text">
                            <h4>Nous écrire</h4>
                            <span>{{texte}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-content pt-5 pb-5">
            <div class="row">
                <div class="col-xl-4 col-lg-4 mb-50">
                    <div class="footer-widget">
                        <div class="footer-logo">
                            <a href=""><img src="/assets/image_mss_transp.png" class="img-fluid" alt="logo"></a>
                        </div>
                        <div class="footer-text">
                            <p>MSS Consulting, opérateur de la plateforme générique de dématérialisation SPADES, sera votre meilleur partenaire  pour le deploiment de vos projets de transformation digitale.</p>
                        </div>
                        <div class="footer-social-icon">
                            <span>Suivez-nous</span>
                            <a href="#"><i class="fa fa-facebook-square"></i></a>
                            <a href="#"><i class="fa fa-google-plus-square"></i></a>
                            <a href="#"><i class="fa fa-twitter"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 mb-30">
                    <div class="footer-widget">
                        <div class="footer-widget-heading">
                            <h3>Liens Utiles</h3>
                        </div>
                        <ul>
                            <li><a href="#">Accueil</a></li>
                            <li><a href="#">À propos</a></li>
                            <li><a href="#">Services</a></li>
                            <li><a href="#">Portfolio</a></li>
                            <li><a href="#">Contact</a></li>
                            <li><a href="#">À propos de nous</a></li>
                            <li><a href="#">Nos services</a></li>
                            <li><a href="#">Équipe d'experts</a></li>
                            <li><a href="#">Nous contacter</a></li>
                            <li><a href="#">Dernières nouvelles</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 mb-50">
                    <div class="footer-widget">
                        <div class="footer-widget-heading">
                            <h3>S'abonner</h3>
                        </div>
                        <div class="footer-text mb-25">
                            <p>Ne manquez pas de vous abonner à nos nouveaux flux, veuillez remplir le formulaire ci-dessous.</p>
                        </div>
                        <div class="subscribe-form">
                            <form action="#">
                                <input type="text" placeholder="Adresse e-mail">
                                <button><i class="fa fa-paper-plane"></i></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="copyright-area">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-lg-6 text-center text-lg-left">
                    <div class="copyright-text">
                        <p>Copyright &copy; 2024, Tous droits réservés <a href="">Anup</a></p>
                    </div>
                </div>
            
                
            </div>
        </div>
    </div>
</footer>