<div class="mt-1">
  <div class="login-form">
<div *ngIf="step1 == true">
<div class="container mt-5">
  <div class="row justify-content-center">
    <div class="col-lg-10">
      <div class="card border-0 shadow-sm w-100">

          <h4>Ajouter un chauffeur</h4>
<hr>
<p style="font-size: small; color: red;">Noté que les champs marqués comme * sont obligatoires.</p>

        <div class="card-body p-4">
          <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-md-6">
            <div class="form-group mb-3">
             
              <input id="userName" class="form-control input--style-5" formControlName="userName" placeholder="Nom d'utilisateur * " />
              <div *ngIf="userName?.invalid && (userName?.touched || userName?.dirty)" class="text-danger mt-2">
                Le nom d'utilisateur est requis
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mb-3">
              <input id="email" type="email" class="form-control input--style-5" formControlName="email" placeholder="Email *" />
              <div *ngIf="email?.invalid && (email?.touched || email?.dirty)" class="text-danger mt-2">
                Un email valide est requis
              </div>
            </div>
          </div>
          </div>

          <div class="row">
            <div class="col-md-6">
            <div class="form-group mb-3">
              <input id="password" type="password" class="form-control input--style-5" formControlName="password" placeholder="Mot de passe *" />
              <div *ngIf="password?.invalid && (password?.touched || password?.dirty)" class="text-danger mt-2">
                Le mot de passe est requis (min 8 caractères)
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mb-4">
              <input id="confirmPassword" type="password" class="form-control input--style-5" formControlName="confirmPassword" placeholder="Confirmez mot de passe *" />
              <div *ngIf="confirmPassword?.invalid && (confirmPassword?.touched || confirmPassword?.dirty)" class="text-danger mt-2">
                {{ confirmPasswordError }}
              </div>
            </div>
</div>
          </div>


          <div class="row">
            <div class="col-md-6">
            <div class="form-group mb-3">
              <input id="firstName" class="form-control input--style-5" formControlName="firstName" placeholder="Prénom * " />
              <div *ngIf="firstName?.invalid && (firstName?.touched || firstName?.dirty)" class="text-danger mt-2">
                Le prénom est requis
              </div>
            </div>
            </div>
            <div class="col-md-6">
            <div class="form-group mb-3">
              <input id="lastName" class="form-control input--style-5" formControlName="lastName" placeholder="Nom * " />
              <div *ngIf="lastName?.invalid && (lastName?.touched || lastName?.dirty)" class="text-danger mt-2">
                Le nom est requis
              </div>
            </div>
          </div></div>
<div class="row">
<div class="col-md-6">
            <div class="form-group mb-3">
              <input id="phoneNumber" class="form-control input--style-5" formControlName="phoneNumber" placeholder="Numéro de téléphone *" />
              <div *ngIf="phoneNumber?.invalid && (phoneNumber?.touched || phoneNumber?.dirty)" class="text-danger mt-2">
                Le numéro de téléphone est requis
              </div>
            </div>
</div>

<div class="col-md-6">
            <div class="form-group mb-3">
              <input id="cin" class="form-control input--style-5" formControlName="cin" placeholder="Numéro de CIN" />
              <div *ngIf="cin?.invalid && (cin?.touched || cin?.dirty)" class="text-danger mt-2">
                Le numéro de CIN est requis
              </div>
            </div>
</div>
</div>

<div class="row">
<div class="col-md-6">
            <div class="form-group mb-3">
              <input id="address" class="form-control input--style-5" formControlName="address" placeholder="Adresse *" />
              <div *ngIf="address?.invalid && (address?.touched || address?.dirty)" class="text-danger mt-2">
                L'adresse est requise
              </div>
            </div>
</div>
<div class="col-md-6">
            <div class="form-group mb-3">
              <select id="city" formControlName="city" class="form-select">
                <option value="" disabled selected>Code postal, Ville *</option>
                <option *ngFor="let ville of villesList" [value]="ville.codePostal + ', ' + ville.ville">
                    {{ ville.codePostal }}, {{ ville.ville }} 
                </option>
            </select>
              <div *ngIf="city?.invalid && (city?.touched || city?.dirty)" class="text-danger mt-2">
                La ville est requise
              </div>
            </div>
</div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group mb-3">
                <input id="dateOfBirth" type="date" class="form-control input--style-5" formControlName="dateOfBirth" />
                <div *ngIf="dateOfBirth?.invalid && (dateOfBirth?.touched || dateOfBirth?.dirty)" class="text-danger mt-2">
                  La date de naissance est requise
                </div>
              </div>
            </div>
   

            <div class="col-md-6">

              <div class="form-group mb-3">
                <select id="experienceYears" class="form-control input--style-5" formControlName="experienceYears">
                  <option value="">Sélectionner les années d'expérience *</option>
                  <option value="0-1">0-1 an (Débutant)</option>
                  <option value="1-3">1-3 ans (Junior)</option>
                  <option value="3-5">3-5 ans (Intermédiaire)</option>
                  <option value="5-10">5-10 ans (Senior)</option>
                  <option value="10+">10+ ans (Expert)</option>
                </select>
                <div *ngIf="experienceYears?.invalid && (experienceYears?.touched || experienceYears?.dirty)" class="text-danger mt-2">
                  Années d'expérience est requis
                </div>
              </div>
              
            </div>
          </div>
            

          <div class="row">
            <div class="col-md-6">
              <div class="form-group mb-3">
                <select id="ratings" class="form-control input--style-5" formControlName="ratings">
                  <option value="" disabled selected>Choisissez une évaluation *</option>
                  <option value="1">★☆☆☆☆</option>
                  <option value="2">★★☆☆☆</option>
                  <option value="3">★★★☆☆</option>
                  <option value="4">★★★★☆</option>
                  <option value="5">★★★★★</option>
                </select>
                <div *ngIf="ratings?.invalid && (ratings?.touched || ratings?.dirty)" class="text-danger mt-2">
                  Les évaluations sont requises
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group mb-3">
                  <select id="drivingLicenseCategory" class="form-control input--style-5" formControlName="drivingLicenseCategory">
                  <option value="">Sélectionner la catégorie du permis de conduire *</option>
                  <option value="A">A - Moto</option>
                  <option value="B">B - Voiture</option>
                  <option value="C">C - Poids Lourd</option>
                  <option value="D">D - Autobus</option>
                  <option value="E">E - Remorque</option>
                  <!-- Ajoutez d'autres options selon les catégories disponibles -->
                </select>
              </div>
            </div>
            <div class="col-md-6">

              <div class="form-group mb-3">
                <input id="driverPermisId" class="form-control input--style-5" formControlName="driverPermisId" placeholder="Entrez l'identifiant du permis de conduire *" />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group mb-3">
               <input id="emergencyContactPhone" class="form-control input--style-5" formControlName="emergencyContactPhone" placeholder="Entrez le numéro de téléphone du contact d'urgence" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-3">
                  <select id="maritalStatus" class="form-control input--style-5" formControlName="maritalStatus">
                  <option value="">Sélectionner l'état matrimonial *</option>
                  <option value="Célibataire">Célibataire</option>
                  <option value="Marié(e)">Marié(e)</option>
                </select>
                <div *ngIf="maritalStatus?.invalid && (maritalStatus?.touched || maritalStatus?.dirty)" class="text-danger mt-2">
                  L'état matrimonial est requis
                </div>
              </div>
            </div>
          </div>
            <button type="submit" class="button-container">S'inscrire</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

<div *ngIf="step2 == true" class="section" style="width: 30rem;">

  <div class="container">
    <h5>Téléchargement d'image CIN :</h5>
  
    <div class="form-group">
      <input class="form-control" type="file" (change)="onFileChanged($event)" />
    </div>
  
    <button (click)="onUpload()">Upload Image</button> 
 
  </div>
</div>

<div *ngIf="step3 == true" class="section" style="width: 30rem;">

  <div class="container mt-3">
    <h5>Téléchargement d'image permis :</h5>
  
    <div class="form-group">
      <input class="form-control" type="file" (change)="onFileChanged($event)" />
    </div>
  
    <button (click)="onUpload_2()">Upload Image</button> 
 
  </div>
</div>

</div>

</div>

