<div class="container">
  <h1 style=" margin-bottom: 2rem; text-align: center;font-family: 'Open Sans',sans-serif; font-weight: 900; color: #002e51;">Liste des Véhicules</h1>
  <p style="color: gray;  text-align: center;">
    Gérez vos véhicules et recherchez-les selon divers critères ou statuts. Notez que seuls les statuts « Disponible » et « Non disponible » sont utilisés, les autres étant considérés comme « Non disponible ».
  </p>
  <hr style="width: 60rem;  margin: 15px auto;"> 
  <div class="row mb-3">
    <div class="col text-end">
      <button class="btn btn-primary mt-3" (click)="addVehicule()">
        <i class="fa fa-plus-circle me-2"></i> Ajouter
      </button>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col-md-8">
      <input type="text" [(ngModel)]="searchText" class="form-control search-input" placeholder="Rechercher un véhicule..." />
    </div>
    <div class="col-md-4 text-end">
      <p class="mb-0">
        <span class="badge bg-primary me-2">Total : {{ totalVehicules }}</span>
        <span class="badge bg-success me-2">Disponibles : {{ availableVehicules }}</span>
        <span class="badge bg-danger">Non Disponibles : {{ unavailableVehicules }}</span>
      </p>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col-md-4">
      <select [(ngModel)]="selectedStatus" class="form-select" (change)="filterByStatus()">
        <option value="">Tous les statuts</option>
        <option value="Disponible">Disponible</option>
        <option value="Non disponible">Non disponible</option>
      </select>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4 mb-4" *ngFor="let vehicule of vehicules | paginate: { itemsPerPage: 6, currentPage: page, totalItems: totalLength } | filter:searchText">
      <div class="card h-100 shadow-lg">
        <img *ngIf="vehicule.image" [src]="'data:image/jpeg;base64,' + vehicule.image.picByte" class="card-img-top" alt="Image du véhicule">
        <div class="card-body">
          <h5 class="card-title text-primary">{{ vehicule.marque }} - {{ vehicule.modele }}</h5>
          <p class="card-text"><strong>Immatriculation :</strong> {{ vehicule.immatriculation }}</p>
          <p class="card-text"><strong>Statut :</strong> <span [ngClass]="{'text-danger': vehicule.statut === 'Non disponible'}">{{ vehicule.statut }}</span></p>
          <p class="card-text"><strong>Année :</strong> {{ vehicule.annee }}</p>
          <p class="card-text"><strong>Capacité :</strong> {{ vehicule.capacite }} tonnes</p>
          <p class="card-text"><strong>Kilométrage :</strong> {{ vehicule.kilometrage }} km</p>
          <p class="card-text"><strong>Carburant :</strong> {{ vehicule.typeCarburant }}</p>
          <p class="card-text">
            <strong>Visite Technique :</strong>
            <span [ngClass]="{'text-danger': isDatePast(vehicule.visiteTechnique)}">{{ vehicule.visiteTechnique | date }}</span>
          </p>
          <p class="card-text">
            <strong>Expiration Assurance :</strong>
            <span [ngClass]="{'text-danger': isDatePast(vehicule.expirationAssurance)}">{{ vehicule.expirationAssurance | date }}</span>
          </p>
          <p class="card-text">
            <strong>Expiration Permis de Circulation :</strong>
            <span [ngClass]="{'text-danger': isDatePast(vehicule.expirationPermisCirculation)}">{{ vehicule.expirationPermisCirculation | date }}</span>
          </p>
        </div>
        <div class="card-footer text-center">
          <button class="btn btn-outline-primary me-2" (click)="modifierVehicule(vehicule)">
            <i class="fa fa-pencil-square-o"></i>
          </button>
          <button class="btn btn-outline-danger" (click)="deleteVehicule(vehicule.id)">
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-center mt-3">
    <pagination-controls (pageChange)="page = $event"></pagination-controls>
  </div>
</div>
