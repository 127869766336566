import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private baseUrl = environment.APP_API_URL + '/api/messages';

  constructor(private http: HttpClient) { }

  sendMessage(senderId: number, receiverId: number, content: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/send`, null, {
      params: {
        senderId: senderId.toString(),
        receiverId: receiverId.toString(),
        content
      }
    });
  }

  getMessagesBetweenUsersForTransporteur(user1Id: number, user2Id: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}/between-transporteur`, {
      params: {
        user1Id: user1Id.toString(),
        user2Id: user2Id.toString()
      }
    });
  }
  getMessagesBetweenUsersForChauffeur(user1Id: number, user2Id: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}/between-chauffeur`, {
      params: {
        user1Id: user1Id.toString(),
        user2Id: user2Id.toString()
      }
    });
  }

  getUnreadMessagesCount(userId: number): Observable<number> {
    return this.http.get<number>(`${this.baseUrl}/unread-count/${userId}`);
  } 

  markMessagesAsRead(receiverId: number): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/mark-as-read/${receiverId}`, {});
  }
}
