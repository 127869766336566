import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/Services/user-service.service';

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-list-transporteurs-admin',
  templateUrl: './list-transporteurs-admin.component.html',
  styleUrls: ['./list-transporteurs-admin.component.css']
})
export class ListTransporteursAdminComponent implements OnInit {
  users: any[] = [];

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.userService.getUsers().subscribe(
      (data) => {
        this.users = data.filter(user => user.role === 'Transporteur');
        console.log('Users fetched successfully', this.users);
      },
      (error) => {
        console.error('Error fetching users', error);
      }
    );
  }

  exportToExcel(): void {
    // Extraire uniquement les attributs userName, password, email
    const exportData = this.users.map(user => ({
      firstName: user.firstName, 
      lastName: user.lastName,
      userName: user.userName,
      password: user.password,
      email: user.email,
      phone: user.phoneNumber
    }));

    // Créer un nouveau workbook avec les données filtrées
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Users');

    // Sauvegarde sous forme de fichier Excel
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, 'users_data');
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    saveAs(data, `${fileName}_${new Date().getTime()}.xlsx`);
  }
}

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
