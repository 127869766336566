import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // private apiUrl = 'http://localhost:9067/api/user/login';
  private apiUrl = environment.APP_API_URL +"/api/user/login";
  
  constructor(private http: HttpClient) { }

  login(username: string, password: string): Observable<any> {
    return this.http.post<any>(this.apiUrl, { userName: username, password }).pipe(
      tap(response => {
        // Store the JWT token in localStorage
        localStorage.setItem('token', response.token);
        localStorage.setItem('idUser', response.id);

        // Optionally, store other user details if needed
        // localStorage.setItem('currentUser', response.userName);
      })
    );
  }
}
