<div class="container">
    <div class="row">
        <div class="col-10 ">
            <div *ngIf="chauffeur" class="section">
                <h2>{{ chauffeur.firstName }} {{ chauffeur.lastName }}</h2>
                <hr>
                <p>Email : {{ chauffeur.email }}</p>
                <p>Rôle : {{ chauffeur.role }}</p>
                <p>Téléphone : {{ chauffeur.phoneNumber }}</p>
                <p>Adresse : {{ chauffeur.address }}</p>
                <p>Ville : {{ chauffeur.city }}</p>
                <p>CIN : {{ chauffeur.cin }}</p>
                <p>Code Postal : {{ chauffeur.postalCode }}</p>
                <p>Date de Naissance : {{ chauffeur.dateOfBirth | date: 'dd/MM/yyyy' }}</p>
                <p>Type de Véhicule : {{ chauffeur.vehicleType }}</p>
                <p>Années d'Expérience : {{ chauffeur.experienceYears }}</p>
                <p>Évaluations : {{ chauffeur.ratings }}</p>
                <p>Commentaires : {{ chauffeur.comments }}</p>
                <p>Incidents sur les 12 Derniers Mois : {{ chauffeur.incidentsLast12Months }}</p>
                <p>Évaluations Professionnelles Mensuelles : {{ chauffeur.monthlyProfessionalRatings }}</p>
                <p>Évaluation Client : {{ chauffeur.clientRating }}</p>
                <p>Catégorie de Permis de Conduire : {{ chauffeur.drivingLicenseCategory }}</p>
                <p>ID du Permis de Conduire : {{ chauffeur.driverPermisId }}</p>
                <p>Téléphone de Contact en Cas d'Urgence : {{ chauffeur.emergencyContactPhone }}</p>
                <p>État Civil : {{ chauffeur.maritalStatus }}</p>
                <p>Créé le : {{ chauffeur.created_at | date: 'dd/MM/yyyy HH:mm:ss' }}</p>
                <p>Modifié le : {{ chauffeur.updated_at | date: 'dd/MM/yyyy HH:mm:ss' }}</p>
                <!-- Ajoutez d'autres informations ici -->
              </div>
        </div> 
        <div class="col-2 custom-center">
         
            <div *ngIf="imageNames.length > 0" class="section">
                <h2>Liste des documents</h2>
                <ul>
                  <li *ngFor="let name of imageNames">
                    <button (click)="openImageModal(name)"><i class="fa fa-id-card" aria-hidden="true"></i> Voir</button>
                  </li>
                </ul>
              </div>
            
              <div *ngIf="errorMessage" class="section">
                <p>{{ errorMessage }}</p>
              </div>
            </div>
        </div>
    </div>

      
    
  
   
  