import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

interface Mission {
  id: number;
  dateDebut: string;
  dateFin: string;
  description: string;
  idChauffeur: number;
  statut: string;
  idTranspoteur: number;  
  vehicule: Vehicule;
  devis: Devis;
}

interface Devis {
  id: number;
  villeDepart: string;
  villeArrivee: string;
  nomClient: string;
  telephoneClient: string;
  emailClient: string;
}

interface Chauffeur {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  address: string;
  city: string;
  cin: string;
  dateOfBirth: string;
  postalCode: string;
  vehicleType: string;
  experienceYears: string;
  ratings: string;
  drivingLicenseCategory: string;
  driverPermisId: string;
  incidentsLast12Months: string;
  monthlyProfessionalRatings: string;
  maritalStatus: string;
}

interface Vehicule {
  id: number;
  type: string;
  immatriculation: string;
  statut: string;
  marque: string;
  annee: number;
  capacite: number;
  kilometrage: number;
  typeCarburant: string;
  categorie: string;
}

@Component({
  selector: 'app-mission-transporteur',
  templateUrl: './mission-transporteur.component.html',
  styleUrls: ['./mission-transporteur.component.css']
})
export class MissionTransporteurComponent implements OnInit {

  days: string[] = [];
  chauffeurs: Chauffeur[] = [];
  schedule: { [key: string]: { [key: string]: Mission[] } } = {};
  view: 'week' | 'day' | 'month' = 'day';
  totalMissions: number = 0;
  searchText = '';

  totalMissionsByStatus: { [key: string]: number } = {};


  private missionsApiUrl = environment.APP_API_URL + "/api/missions";
  private chauffeurApiUrl = environment.APP_API_URL + "/api/user";

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.loadMissions();
    this.initializeDays();
  }

  formatDateTime(dateString: string): string {
    const date = new Date(dateString);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  }

  loadMissions(): void {
    const idTranspoteur = Number(localStorage.getItem('idUser'));
  
    this.http.get<Mission[]>(this.missionsApiUrl).pipe(
      map((missions) => missions.filter(mission => mission.idTranspoteur === idTranspoteur)),
      map((filteredMissions) => {
        this.totalMissions = filteredMissions.length;
        this.calculateMissionStatistics(filteredMissions); // Compter les missions par statut
        return this.processMissions(filteredMissions);
      })
    ).subscribe((schedule) => {
      this.schedule = schedule;
      this.extractChauffeurs();
    });
  }
  
  calculateMissionStatistics(missions: Mission[]): void {
    // Réinitialiser les compteurs
    this.totalMissionsByStatus = {
      'Prise en charge marchandise': 0,
      'Marchandise livrée': 0,
      'Revenu au parking': 0,
      'Mission démarrée': 0,
      'Mission créée': 0,
      'Retourner au parking': 0
    };
  
    missions.forEach(mission => {
      if (this.totalMissionsByStatus[mission.statut] !== undefined) {
        this.totalMissionsByStatus[mission.statut]++;
      }
    });
  }
  

  processMissions(missions: Mission[]): { [key: string]: { [key: string]: Mission[] } } {
    const schedule: { [key: string]: { [key: string]: Mission[] } } = {};

    missions.forEach(mission => {
      const startDate = new Date(mission.dateDebut);
      const endDate = new Date(mission.dateFin);
      const currentDate = new Date(startDate);

      while (currentDate <= endDate) {
        const dayString = currentDate.toISOString().split('T')[0];
        if (!schedule[dayString]) schedule[dayString] = {};
        if (!schedule[dayString][mission.idChauffeur]) schedule[dayString][mission.idChauffeur] = [];

        schedule[dayString][mission.idChauffeur].push(mission);
        currentDate.setDate(currentDate.getDate() + 1);
      }
    });

    return schedule;
  }

  extractChauffeurs(): void {
    const chauffeurIds = new Set<number>();

    Object.values(this.schedule).forEach(day => {
      Object.keys(day).forEach(chauffeurId => {
        chauffeurIds.add(Number(chauffeurId));
      });
    });

    const chauffeurRequests = Array.from(chauffeurIds).map(id =>
      this.http.get<Chauffeur>(`${this.chauffeurApiUrl}/${id}`)
    );

    forkJoin(chauffeurRequests).subscribe((chauffeurs) => {
      this.chauffeurs = chauffeurs;
    });
  }

  initializeDays(): void {
    const today = new Date();
    if (this.view === 'week') {
      this.days = Array.from({ length: 7 }, (_, i) => {
        const date = new Date(today);
        date.setDate(date.getDate() + i);
        return date.toISOString().split('T')[0];
      });
    } else if (this.view === 'day') {
      this.days = [today.toISOString().split('T')[0]];
    } else if (this.view === 'month') {
      this.days = Array.from({ length: 30 }, (_, i) => {
        const date = new Date(today);
        date.setDate(date.getDate() + i);
        return date.toISOString().split('T')[0];
      });
    }
  
    // Filtrer les missions pour la période sélectionnée et recalculer les statistiques
    const filteredMissions = this.filterMissionsByDays(this.days);
    this.totalMissions = filteredMissions.length;
    this.calculateMissionStatistics(filteredMissions);
  }
  
  filterMissionsByDays(days: string[]): Mission[] {
    let filteredMissions: Mission[] = [];
    
    days.forEach(day => {
      Object.keys(this.schedule[day] || {}).forEach(chauffeurId => {
        filteredMissions = [...filteredMissions, ...this.schedule[day][chauffeurId]];
      });
    });
  
    return filteredMissions;
  }
  
  changeView(view: 'week' | 'day' | 'month'): void {
    this.view = view;
    this.initializeDays();
  }

  getColor(chauffeur: Chauffeur): string {
    return chauffeur.id % 2 === 0 ? 'blue' : 'green';
  }

  getTasks(chauffeurId: number, date: string): Mission[] {
    return this.schedule[date] && this.schedule[date][chauffeurId] || [];
  }

  showChauffeurDetails(chauffeur: Chauffeur): void {
    Swal.fire({
      title: `${chauffeur.firstName} ${chauffeur.lastName}`,
      html: `
        <p><strong>Email:</strong> ${chauffeur.email}</p>
        <p><strong>Téléphone:</strong> ${chauffeur.phoneNumber}</p>
        <p><strong>Adresse:</strong> ${chauffeur.address}, ${chauffeur.city}, ${chauffeur.postalCode}</p>
        <p><strong>CIN:</strong> ${chauffeur.cin}</p>
        <p><strong>Date de Naissance:</strong> ${chauffeur.dateOfBirth}</p>
        <p><strong>Type de Véhicule:</strong> ${chauffeur.vehicleType}</p>
      `,
      confirmButtonText: 'Fermer'
    });
  }

  showVehiculeDetails(vehicule: Vehicule): void {
    Swal.fire({
      title: `Détails du Véhicule`,
      html: `
        <p><strong>Immatriculation:</strong> ${vehicule.immatriculation}</p>
        <p><strong>Marque:</strong> ${vehicule.marque}</p>
        <p><strong>Année:</strong> ${vehicule.annee}</p>
        <p><strong>Capacité:</strong> ${vehicule.capacite}</p>
        <p><strong>Kilométrage:</strong> ${vehicule.kilometrage}</p>
        <p><strong>Type de Carburant:</strong> ${vehicule.typeCarburant}</p>
        <p><strong>Catégorie:</strong> ${vehicule.categorie}</p>
      `,
      confirmButtonText: 'Fermer'
    });
  }

  showMissionDetails(mission: Mission): void {
    Swal.fire({
      title: 'Détails de la Mission',
      html: `
        <p><strong>Date de Début:</strong> ${this.formatDateTime(mission.dateDebut)}</p>
        <p><strong>Date de Fin:</strong> ${this.formatDateTime(mission.dateFin)}</p>
        <p><strong>Description:</strong> ${mission.description}</p>
        <p><strong>Statut:</strong> ${mission.statut}</p>
        <p><strong>Client:</strong> ${mission.devis.nomClient}</p>
        <p><strong>Ville de Départ:</strong> ${mission.devis.villeDepart}</p>
        <p><strong>Ville d'Arrivée:</strong> ${mission.devis.villeArrivee}</p>
      `,
      confirmButtonText: 'Fermer'
    });
  }
}
