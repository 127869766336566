<div class="pie-container">
    <div class="form-group">
      <label for="startDate">Date de Début:</label>
      <input 
        type="date" 
        id="startDate" 
        [(ngModel)]="startDate" 
        (ngModelChange)="filterMissions()" 
        name="startDate" 
      />
  
      <label for="endDate">Date de Fin:</label>
      <input 
        type="date" 
        id="endDate" 
        [(ngModel)]="endDate" 
        (ngModelChange)="filterMissions()" 
        name="endDate" 
      />
  
      <label for="status">Statut:</label>
      <select 
        id="status" 
        [(ngModel)]="statusFilter" 
        (ngModelChange)="filterMissions()" 
        name="status"
      >
        <option value="">Tous</option>
        <option *ngFor="let status of statuses" [value]="status">{{ status }}</option>
      </select>
    </div>
  
    <!-- Affichage des statistiques -->
    <p>Total missions : <span class="badge badge-primary">{{ filteredMissions.length }}</span></p>
    <div class="statistics-container">
      <div class="pie-wrapper" *ngFor="let status of filteredStatuses">
        <div class="pie" [ngStyle]="{'--p': getPercentage(status) + '%', '--c': getColor(status)}">
          <span>{{ getPercentage(status) | number: '1.0-0' }}%</span>
        </div>
        <div class="pie-label">{{ status }}</div>
      </div>
    </div>
  </div>
  
