<div class="chat-container">
  <div class="chat-sidebar">
    <h2>Conversations</h2>
    <ul class="user-list">
      <li *ngFor="let user of users"
          class="user-item"
          [class.active]="user === selectedUser"
          (click)="selectUser(user)">

        <img [src]="user?.image?.picByte ? 'data:image/jpeg;base64,' + user?.image.picByte : '/assets/profile-avatar.png'"
             alt="Profile Picture" class="user-avatar">
        {{ user.firstName }} {{ user.lastName }}


        <span *ngIf="user.unreadMessagesCount > 0" class="notification-dot">{{ user.unreadMessagesCount }} </span>

      </li>
    </ul>
  </div>

  <div class="chat-main" *ngIf="selectedUser">
    <div class="chat-header">
      <div class="user-info">
        <img [src]="selectedUser?.image ? 'data:image/jpeg;base64,' + selectedUser?.image.picByte : '/assets/profile-avatar.png'"
             alt="Profile Picture" class="user-avatar">
        <h2>Discussion avec {{ selectedUser.firstName }} {{ selectedUser.lastName }}</h2>
      </div>
    </div>

    <div class="chat-messages">
      <div *ngFor="let message of messages"
           [ngClass]="{'sent': message.sender.id === currentUser.id, 'received': message.sender.id !== currentUser.id, 'read': message.isViewed}"
           class="message">
        <p><strong>{{ message.sender.firstName }} {{ message.sender.lastName }}:</strong> {{ message.content }}</p>
        <span class="message-time">{{ message.timestamp | date:'shortTime' }}</span>
      </div>
    </div>

    <div class="chat-input">
      <input type="text" [(ngModel)]="newMessage" placeholder="Tapez un message...">
      <button class="send-button" (click)="sendMessage()">Envoyer</button>
    </div>
  </div>
</div>
