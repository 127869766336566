import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MissionService } from 'src/app/Services/mission.service';
import { UserService } from 'src/app/Services/user-service.service';
import { VehiculeService } from 'src/app/Services/vehicule.service';
import { VilleService } from 'src/app/Services/ville.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-create-mission-with-devis',
  templateUrl: './create-mission-with-devis.component.html',
  styleUrls: ['./create-mission-with-devis.component.css']
})
export class CreateMissionWithDevisComponent implements OnInit {

  missionForm!: FormGroup;
  vehicules: any[] = [];
  chauffeurs: any[] = [];
  idDevis: number = 0;
  userId: string | null = null;
  devis: any | null = null;
  villesList: any[] = [];
  constructor(
    private fb: FormBuilder,
    private missionService: MissionService,
    private vehiculeService: VehiculeService,
    private userService: UserService, private villeService: VilleService
  ) {}

  ngOnInit(): void {

    this.missionForm = this.fb.group({

      villeDepart: ['', Validators.required],
      villeArrivee: ['', Validators.required],
      nomClient: ['', Validators.required],
      telephoneClient: ['', Validators.required],
      emailClient: ['', Validators.required],
      dateFin: ['', Validators.required],
      dateDebut: ['', Validators.required],
      description: ['', Validators.required],
      vehiculeId: ['', Validators.required],
      chauffeurId: ['', Validators.required]
    });

    this.userId = this.isUserAuthenticated();


    this.vehiculeService.getVehicules().subscribe(
      (data: any[]) => {
        if (this.userId !== null) {
          const userIdNumber = parseInt(this.userId, 10);
          this.vehicules = data.filter(item => item.transporteurId === userIdNumber);
        } else {
          this.vehicules = [];
        }
      },
      error => {
        console.error('Erreur lors de la récupération des véhicules :', error);
      }
    );

    // Récupération des chauffeurs
    this.userService.getChauffeurs().subscribe(
      (data: any[]) => {
        if (this.userId !== null) {
          const userIdNumber = parseInt(this.userId, 10);
          this.chauffeurs = data.filter(item => item.transporteurId === userIdNumber);
        } else {
          this.chauffeurs = [];
        }
      },
      error => {
        console.error('Erreur lors de la récupération des chauffeurs :', error);
      }
    );

    this.villeService.getVilles().subscribe(data => {
      this.villesList = data;
    });
  }


  onSubmit(): void {
    if (this.missionForm.valid) {
      const formValue = {
        ...this.missionForm.value,
        transporteurId: window.localStorage.getItem('idUser') // Assigner l'utilisateur courant
      };

      console.log("Valeurs du formulaire", formValue);

      // Appeler le service pour créer la mission
      this.missionService.createWitheDevis(formValue).subscribe(
        response => {
          Swal.fire({
            title: 'Succès!',
            text: 'Mission créée avec succès.',
            icon: 'success',
            confirmButtonText: 'OK'
          });
        },
        error => {
          Swal.fire({
            title: 'Erreur!',
            text: 'Erreur lors de la création de la mission.',
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      );
    }
  }


  isUserAuthenticated(): string | null {
    return localStorage.getItem('idUser');
  }

}
