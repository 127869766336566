<div class="home-container">
    <!-- Hero Section -->
    <section class="hero-section text-center">
      <div class="container">
        <h1 class="display-4">Votre solution de transport de marchandises</h1>
        <p class="lead">Réservez, suivez, et gérez vos transports en toute simplicité.</p>
        <a class="btn btn-primary btn-lg" href="#services" role="button">Demander un devis</a>
      </div>
    </section>
  
    <!-- About Section -->
    <section id="about" class="about-section py-5">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6">
            <h2>À propos de Mon Transporteur</h2>
            <p>Mon Transporteur est la solution idéale pour la gestion de vos transports de véhicules, offrant une interface intuitive, un suivi en temps réel et un support client 24/7. Notre objectif est de simplifier vos opérations de transport, tout en assurant une sécurité et une efficacité optimales.</p>
          </div>
          <div class="col-md-6">
            <img src="/assets/img_app.jpg" alt="À propos" class="img-fluid rounded">
          </div>
        </div>
      </div>
    </section>
  
    <!-- Services Section -->
    <section id="services" class="services-section py-5 bg-light">
      <div class="container">
        <h2 class="text-center">Nos Services</h2>
        <div class="row mt-4">
          <div class="col-md-4">
            <div class="service-box text-center">
              <img src="/assets/img1.jpg" alt="Transport" class="img-fluid mb-3">
              <h3>Transport de marchandises</h3>
              <p>Assurez un transport sécurisé et rapide de vos marchandises avec nos services.</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="service-box text-center">
              <img src="/assets/img2.jpg" alt="Tracking" class="img-fluid mb-3">
              <h3>Suivi en temps réel</h3>
              <p>Suivez l'état de votre transport à chaque étape du processus.</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="service-box text-center">
              <img src="/assets/img3.png" alt="Support" class="img-fluid mb-3">
              <h3>Support 24/7</h3>
              <p>Profitez d'une assistance client disponible 24 heures sur 24, 7 jours sur 7.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  
    <!-- Testimonials Section -->
    <section id="testimonials" class="testimonials-section py-5">
      <div class="container">
        <h2 class="text-center">Ce que disent nos clients</h2>
        <div class="row mt-4">
          <div class="col-md-4">
            <div class="testimonial-box">
              <p>"Mon Transporteur a simplifié nos opérations de transport. Le suivi en temps réel est un atout majeur pour notre entreprise."</p>
              <h5>- Sami Slim, Directeur Logistique</h5>
            </div>
          </div>
          <div class="col-md-4">
            <div class="testimonial-box">
              <p>"Je recommande vivement Mon Transporteur pour sa facilité d'utilisation et son service client exceptionnel."</p>
              <h5>- Ahmed Gharbi, Gestionnaire de flotte</h5>
            </div>
          </div>
          <div class="col-md-4">
            <div class="testimonial-box">
              <p>"Nous avons réduit nos coûts de transport grâce à l'efficacité de Mon Transporteur. Un incontournable pour toute entreprise."</p>
              <h5>- Mohamed Ben Ali, Responsable Transport</h5>
            </div>
          </div>
        </div>
      </div>
    </section>
  
  
  
  
    <!-- Transporters Section -->
    <section class="transporters-section text-center">
      <div class="container">
        <h2>Nos Transporteurs</h2>
        <div class="row">
          <div class="col-md-4">
            <div class="card mb-4">
              <img src="/assets/img4.jpg" class="card-img-top" alt="Transporteur 1">
              <div class="card-body">
                <h5 class="card-title">Transporteur Alpha</h5>
                <p class="card-text">Spécialisé dans le transport de marchandises de luxe avec une couverture européenne.</p>
                <a href="#" class="btn btn-primary">Voir le profil</a>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card mb-4">
              <img src="/assets/img5.jpg" class="card-img-top" alt="Transporteur 2">
              <div class="card-body">
                <h5 class="card-title">Transporteur Beta</h5>
                <p class="card-text">Expert en transport de  marchandises utilitaires et de gros volumes.</p>
                <a href="#" class="btn btn-primary">Voir le profil</a>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card mb-4">
              <img src="/assets/img6.jpg" class="card-img-top" alt="Transporteur 3">
              <div class="card-body">
                <h5 class="card-title">Transporteur Gamma</h5>
                <p class="card-text">Fournisseur de services de transport de  marchandises électriques et écologiques.</p>
                <a href="#" class="btn btn-primary">Voir le profil</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  
    <!-- Customer Testimonials Section -->
    <section class="testimonials-section text-center py-5">
      <div class="container">
        <h2>Témoignages de Clients</h2>
        <div class="row">
          <div class="col-md-4">
            <div class="testimonial-box">
              <img src="/assets/profile-avatar.png" alt="Client 1" class="testimonial-img rounded-circle">
              <p class="testimonial-text">"Le service était impeccable, les colis sont arrivés à l'heure et en parfait état. Je recommande vivement !" </p>
              <h5 class="testimonial-name">Alice Dupont</h5>
              <p class="testimonial-role">Directrice Marketing</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="testimonial-box">
              <img src="/assets/profile-avatar.png" alt="Client 2" class="testimonial-img rounded-circle">
              <p class="testimonial-text">"Une expérience exceptionnelle du début à la fin. Le suivi du transport est un vrai plus !" </p>
              <h5 class="testimonial-name">Jean Martin</h5>
              <p class="testimonial-role">Entrepreneur</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="testimonial-box">
              <img src="/assets/profile-avatar.png" alt="Client 3" class="testimonial-img rounded-circle">
              <p class="testimonial-text">"J'ai pu transporter mon palette à travers toute la tunisie sans aucun souci. Merci à l'équipe !" </p>
              <h5 class="testimonial-name">Sophie Leclerc</h5>
              <p class="testimonial-role">Consultante</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- FAQ Section -->
    <section class="faq-section py-5">
      <div class="container">
        <h2 class="text-center">Questions Fréquemment Posées</h2>
        <div class="row">
          <div class="col-md-6">
            <div class="faq-item">
              <h5 class="faq-question">Comment puis-je réserver un transport de véhicule ?</h5>
              <p class="faq-answer">Vous pouvez réserver un transport de véhicule en utilisant notre application en ligne. Il vous suffit de créer un compte, de sélectionner le service souhaité, et de suivre les étapes indiquées.</p>
            </div>
            <div class="faq-item">
              <h5 class="faq-question">Quels types de véhicules pouvez-vous transporter ?</h5>
              <p class="faq-answer">Nous pouvons transporter une large gamme de véhicules, y compris des voitures, motos, camions légers, et même des véhicules spécialisés comme des camions-citernes.</p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="faq-item">
              <h5 class="faq-question">Est-ce que mon véhicule est assuré pendant le transport ?</h5>
              <p class="faq-answer">Oui, tous les véhicules transportés par notre service sont couverts par une assurance complète, vous garantissant une tranquillité d'esprit totale.</p>
            </div>
            <div class="faq-item">
              <h5 class="faq-question">Comment puis-je suivre mon véhicule pendant le transport ?</h5>
              <p class="faq-answer">Vous pouvez suivre l'acheminement de votre véhicule en temps réel grâce à notre système de suivi intégré dans l'application.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  
    <section class="stats-section text-center bg-dark text-white py-5">
      <div class="container">
        <h2>Statistiques en Temps Réel</h2>
        <div class="row">
          <div class="col-md-3">
            <div class="stat-box">
              <h3 class="stat-number">1,234</h3>
              <p class="stat-description">Véhicules en transit</p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="stat-box">
              <h3 class="stat-number">567</h3>
              <p class="stat-description">Transporteurs actifs</p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="stat-box">
              <h3 class="stat-number">89%</h3>
              <p class="stat-description">Clients satisfaits</p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="stat-box">
              <h3 class="stat-number">123</h3>
              <p class="stat-description">Courses en cours</p>
            </div>
          </div>
        </div>
      </div>
    </section>