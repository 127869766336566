import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ChauffeurService } from 'src/app/Services/chauffeur.service';
import { VilleService } from 'src/app/Services/ville.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-update-chouffeur',
  templateUrl: './update-chouffeur.component.html',
  styleUrls: ['./update-chouffeur.component.css']
})
export class UpdateChouffeurComponent implements OnInit {

  registerForm: FormGroup;
  errorMessage: string | undefined;
  userId: string = '';
  villesList: any[] = [];

  constructor(
    private villeService: VilleService,
    private fb: FormBuilder,
    private chauffeurService: ChauffeurService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.registerForm = this.fb.group({
      id: [''],
      userName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneNumber: ['', [Validators.required, Validators.minLength(8)]],
      cin: ['', [Validators.required, Validators.minLength(8)]],
      address: ['', Validators.required],
      city: ['', Validators.required],
      dateOfBirth: ['', Validators.required],
      experienceYears: ['', Validators.required],
      ratings: ['', Validators.required],
      drivingLicenseCategory: ['',Validators.required],
      driverPermisId: ['',Validators.required],
      emergencyContactPhone: ['',Validators.required],
      maritalStatus: ['',Validators.required],
      transporteurId: [null],  
    });
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.userId = id;
      this.chauffeurService.getUserById(this.userId).subscribe(
        response => {
          this.registerForm.patchValue(response);
        },
        error => {
          console.error('Error fetching chauffeur details', error);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Error fetching chauffeur details. Please try again later.'
          });
        }
      );
    } else {
      // Handle the case when 'id' is null, for example, redirect to another page or show an error
      this.router.navigateByUrl('/list-chauffeur');
    }

    this.villeService.getVilles().subscribe(data => {
      this.villesList = data;
    });
  }


  get userName() {
    return this.registerForm.get('userName');
  }

  get email() {
    return this.registerForm.get('email');
  }

  get firstName() {
    return this.registerForm.get('firstName');
  }
  
  get lastName() {
    return this.registerForm.get('lastName');
  }
  
  get phoneNumber() {
    return this.registerForm.get('phoneNumber');
  }

  get cin() {
    return this.registerForm.get('cin');
  }
  get address() {
    return this.registerForm.get('address');
  }
  
  get city() {
    return this.registerForm.get('city');
  }
  
  get postalCode() {
    return this.registerForm.get('postalCode');
  }
  
  get dateOfBirth() {
    return this.registerForm.get('dateOfBirth');
  }
  
  get vehicleType() {
    return this.registerForm.get('vehicleType');
  }

  get experienceYears() {
    return this.registerForm.get('experienceYears');
  }

  get ratings() {
    return this.registerForm.get('ratings');
  }
  get monthlyProfessionalRatings() {
    return this.registerForm.get('monthlyProfessionalRatings');
  }

  get drivingLicenseCategory() {
    return this.registerForm.get('drivingLicenseCategory');
  }
  get driverPermisId() {
    return this.registerForm.get('driverPermisId');
  }
  get emergencyContactPhone() {
    return this.registerForm.get('emergencyContactPhone');
  }
  get maritalStatus() {
    return this.registerForm.get('maritalStatus');
  }

  onSubmit() {
    if (this.registerForm.invalid) {
      return;
    }

    this.chauffeurService.updateUser(this.userId, this.registerForm.value).subscribe(
      response => {
        Swal.fire({
          icon: 'success',
          title: 'Update Successful',
          text: 'Chauffeur details updated successfully.',
          showConfirmButton: true
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigateByUrl('/list-chauffeur');
          }
        });
      },
      error => {
        console.error('Error updating chauffeur', error);
        this.errorMessage = 'Error updating chauffeur. Please try again.';
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: this.errorMessage
        });
      }
    );
  }
}
