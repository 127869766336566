import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ChauffeurService } from 'src/app/Services/chauffeur.service';
import { UserService } from 'src/app/Services/user-service.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

interface CityCode {
  id: number;
  codePostal: string;
  ville: string;
}

interface Vehicle {
  id: number;
  type: string;
  immatriculation: string;
  marque: string;
  modele: string;
  annee: number;
  capacite: number;
  kilometrage: number;
  typeCarburant: string;
  transporteurId: number | null;
  categorie: string;
}

@Component({
  selector: 'app-list-transporteurs',
  templateUrl: './list-transporteurs.component.html',
  styleUrls: ['./list-transporteurs.component.css']
})


export class ListTransporteursComponent implements OnInit {
  users: any[] = [];
  chauffeurs : any[] = [];
  filteredUsers: any[] = [];
  cityCodes: CityCode[] = [];
  vehicles: Vehicle[] = [];
  selectedCityCode: string = '';
  selectedVehicle: string = '';
  page: number = 1;
  totalLength: number = 0;
  searchText: string = '';
  private apiUrl = environment.APP_API_URL +"/api/vehicule";
  private apiUrl2 = environment.APP_API_URL_CODE_VILLE +"/api/gestion_code/codeLibVille";
  

  constructor(private userService: UserService, private http: HttpClient) {}

  ngOnInit(): void {
    this.fetchCitiesAndCodes();
    this.fetchUsers();
    this.fetchVehicles();
    this.fetchChauffeurs();
  }

  fetchCitiesAndCodes(): void {
    this.http.get<CityCode[]>(this.apiUrl2).subscribe(data => {
      this.cityCodes = data;
      console.log('City Codes:', this.cityCodes);  // Debugging
    }, error => {
      console.error('Error fetching city codes:', error);
    });
  }

  fetchUsers(): void {
    this.userService.getUsers().subscribe(data => {
      this.users = data.filter(user => user.role === 'Transporteur');
      console.log('Users:', this.users);  // Debugging
      this.filterUsers();
    }, error => {
      console.error('Error fetching users:', error);
    });
  }

  fetchChauffeurs(): void {
    this.userService.getChauffeurs().subscribe(data => {
      this.chauffeurs = data
      console.log("chauffeurs : ", this.chauffeurs)
    }, error => {
      console.error('Error fetching users:', error);
    });
  }


  fetchVehicles(): void {
    this.http.get<Vehicle[]>(this.apiUrl).subscribe(data => {
      this.vehicles = data;
      console.log('Vehicles:', this.vehicles);  // Debugging
    }, error => {
      console.error('Error fetching vehicles:', error);
    });
  }

  filterUsers(): void {
    this.filteredUsers = this.users.filter(user => {
      const matchesCity = !this.selectedCityCode || user.city.includes(this.selectedCityCode.split(', ')[1]);
      const matchesVehicle = !this.selectedVehicle || this.vehicles.some(vehicle => vehicle.transporteurId === user.id && vehicle.immatriculation === this.selectedVehicle);
      return matchesCity && matchesVehicle;
    });

    this.totalLength = this.filteredUsers.length;
    console.log('Filtered Users:', this.filteredUsers);  // Debugging
    
  }

  showTransporteurDetails(user: any): void {
    // Filtrer les véhicules associés au transporteur
    const associatedVehicles = this.vehicles.filter(vehicle => vehicle.transporteurId === user.id);
    
// Obtenir l'année actuelle
const currentYear = new Date().getFullYear();

// Calculer le nombre de camions associés
const totalTrucks = associatedVehicles.length;

// Calculer la somme des âges des véhicules
let sommeAgeVehicles = 0;
associatedVehicles.forEach(vehicle => {
    const vehicleAge = currentYear - vehicle.annee;
    sommeAgeVehicles += vehicleAge;
});

// Calculer l'âge moyen des véhicules
const moyAgeVehicles = totalTrucks > 0 ? sommeAgeVehicles / totalTrucks : 0;


    
    // Filtrer les chauffeurs associés au transporteur
    const associatedDrivers = this.chauffeurs.filter(driver => 
      driver.transporteurId === user.id 
    );
  
    const totalChauffeurs = associatedDrivers.length;
    
    // Générer la liste des chauffeurs associés
    const driversList = associatedDrivers.map(driver => `
      <li>
        <strong>${driver.firstName} ${driver.lastName}</strong><br>
        Années d'expérience <strong> ${driver.experienceYears} </strong> <br>
     
      </li>
    `).join('');
  
    Swal.fire({
      title: `<p><strong> Fiche transporteur </strong></p>`,
      html: `
      <hr>
        <p><strong>Nom:</strong> ${user.firstName}</p>
        <p><strong>Prénom:</strong> ${user.lastName}</p>
        <p><strong>Email:</strong> ${user.email}</p>
        <p><strong>Numéro de téléphone:</strong> ${user.phoneNumber}</p>
        <p><strong>Adresse:</strong> ${user.address}</p>
        <p><strong>Ville:</strong> ${user.city}</p>
        <p><strong>Code Postal:</strong> ${user.codePostal}</p>
        <hr>
        <p><strong>Véhicules Associés </strong> <span class="badge badge-pill badge-primary"> ${totalTrucks} </span></p>
        <hr>
 
        <ul>
          ${associatedVehicles.map(vehicle => `<li>${vehicle.categorie} - ${currentYear - vehicle.annee} ans - ${vehicle.kilometrage} km</li>`).join('')}
        </ul>
        <p>Âge moyen des véhicules associent <strong> ${moyAgeVehicles} ans </strong></p>
        <hr>
        <p><strong>Chauffeurs Associés </strong>  <span class="badge badge-pill badge-primary"> ${totalChauffeurs} </span></p>
       <hr>
        <ul>
          ${driversList || '<li>Aucun chauffeur associé.</li>'}
        </ul>
      `,
      icon: 'info',
      confirmButtonText: 'Fermer',
      customClass: {
        container: 'text-left',
      
        htmlContainer: 'text-left'
      },
      willOpen: () => {
        const container = Swal.getContainer();
        const htmlContainer = container!.querySelector('.swal2-html-container') as HTMLElement;
        
        if (htmlContainer) {
          htmlContainer.style.textAlign = 'left'; // Appliquer le style si l'élément existe
        }
      }
    });
  }   
}
