<div class="custom-box custom-box-small">
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid d-flex align-items-center justify-content-between">
      <a class="navbar-brand" href="">
        <img src="/assets/logo_transp.png" alt="Logo" class="logo">
      </a>

      <ul class="navbar-nav">
        <li class="nav-item">
          <a *ngIf="!isUserAuthenticated()" class="nav-link" href="/transporteurs">Annuaire des transporteurs</a>
        </li>
        <li class="nav-item">
          <a *ngIf="!isUserAuthenticated()" class="nav-link" href="/devis-client">Demander devis</a>
        </li>
        <li class="nav-item">
          <a *ngIf="!isUserAuthenticated()" class="nav-link" href="/reponse-devis">Réponse devis</a>
        </li>
        <li class="nav-item">
          <a *ngIf="!isUserAuthenticated()" class="nav-link" href="/guide">Guide d'utilisation</a>
        </li>
        <li class="nav-item">
          <a *ngIf="!isUserAuthenticated()" class="nav-link" href="/comment-ca-marche">Comment ça marche ?</a>
        </li>
        <li class="nav-item">
          <a *ngIf="!isUserAuthenticated()" class="nav-link" href="/contact">Contact</a>
        </li>
        <!-- Dropdown pour Connexion et Inscription -->
        <li class="nav-item dropdown" *ngIf="!isUserAuthenticated()">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Actions
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item" href="/register">Inscription</a></li>
            <li><a class="dropdown-item" href="/login">Connexion</a></li>
          </ul>
        </li>
      </ul>
      <div class="navbar-right"  *ngIf="isUserAuthenticated()">
        <!-- Formulaire de recherche -->
        <form class="search-form">
          <input class="search-input" type="search" placeholder="Rechercher..." aria-label="Search">
          <button class="search-btn" type="submit">
            <i class="fa fa-search"></i>
          </button>
        </form>
      
       
      
        <!-- Bouton de notification -->
        <a class="notification-btn" href="#">
          <i class="fa fa-bell"></i>
          <span class="badge">3</span>
        </a>
      
        <!-- Sélecteur de langue -->
        <div class="dropdown language-dropdown">
          <a class="language-btn" id="languageMenu" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-globe"></i>
            <span>Langue</span>
            <i class="fa fa-chevron-down"></i>
          </a>
          <ul class="dropdown-menu language-menu" aria-labelledby="languageMenu">
            <li><a class="dropdown-item" href="#">Français</a></li>
            <li><a class="dropdown-item" href="#">Anglais</a></li>
          </ul>
        </div>
       <!-- Icône de profil utilisateur avec menu déroulant -->
       <div class="dropdown profile-dropdown">
        <a class="profile-btn" id="userMenu" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          <img src="/assets/profile-avatar.png" alt="User Profile" class="profile-icon"> <!-- Icône de profil -->
          <span>Mon Profil</span>
          <i class="fa fa-chevron-down"></i>
        </a>
        <ul class="dropdown-menu profile-menu" aria-labelledby="userMenu">
          <li><a class="dropdown-item" href="/profil">Mon Profil</a></li>
          <li><a class="dropdown-item" href="/home-page">Tableau de bord</a></li>
          <li><hr class="dropdown-divider"></li>
          <li><a class="dropdown-item" href="#" (click)="logout()">Déconnexion</a></li>
        </ul>
      </div>
      </div>    

    </div>
  </nav>

  <!-- Div pour la barre bleue en bas -->
  <div class="blue-bar"></div>
</div>
