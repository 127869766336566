<div class="form-container">
  
  <h1 style="margin-top:2rem; text-align: center;font-family: 'Open Sans',sans-serif; font-weight: 900; color: #002e51;">Nouvelle mission</h1>
  <p style="color: gray;  text-align: center;">
    La date de début est automatiquement sélectionnée en fonction de la date souhaitée par le client lors de la demande de devis.
  </p>
  <hr style="width: 20rem;  margin: 15px auto;">
  <form [formGroup]="missionForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label for="dateDebut">Date Debut:</label>
      <input id="dateDebut" formControlName="dateDebut" type="datetime-local" class="form-control">
    </div>
    <div class="form-group">
      <label for="dateFin">Date Fin:</label>
      <input id="dateFin" formControlName="dateFin" type="datetime-local" class="form-control">
    </div>
    <div class="form-group">
      <label for="description">Description:</label>
      <input id="description" formControlName="description" type="text" class="form-control">
    </div>
    <div class="form-group">
      <label for="vehiculeId">Vehicule:</label>
      <select id="vehiculeId" formControlName="vehiculeId" class="form-control">
        <option *ngFor="let vehicule of vehicules" [value]="vehicule.id">
          {{ vehicule.type }} - {{ vehicule.immatriculation }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label for="chauffeurId">Chauffeur:</label>
      <select id="chauffeurId" formControlName="chauffeurId" class="form-control">
        <option *ngFor="let chauffeur of chauffeurs" [value]="chauffeur.id">
          {{ chauffeur.firstName }} - {{ chauffeur.lastName }}
        </option>
      </select>
    </div>
    <button type="submit" class="btn btn-primary" [disabled]="!missionForm.valid">Create Mission</button>
  </form>
</div>
