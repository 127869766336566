import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RegisterComponent } from './Components/register/register.component';
import { LoginComponent } from './Components/login/login.component';
import { HomePageComponent } from './Components/home-page/home-page.component';
import { TestComponent } from './Components/test/test.component';
import { ChouffeurListComponent } from './Components/chouffeur-list/chouffeur-list.component';
import { ChouffeurFormComponent } from './Components/chouffeur-form/chouffeur-form.component';
import { UpdateChouffeurComponent } from './Components/update-chouffeur/update-chouffeur.component';
import { FileUploadComponent } from './Components/file-upload/file-upload.component';
import { DetailsChauffeurComponent } from './Components/details-chauffeur/details-chauffeur.component';
import { ResetPasswordComponent } from './Components/reset-password/reset-password.component';
import { VehiculesComponent } from './Components/vehicules/vehicules.component';
import { StatistiquesVehiculesComponent } from './Components/statistiques-vehicules/statistiques-vehicules.component';
import { DevisFormClientComponent } from './Components/devis-form-client/devis-form-client.component';
import { ListDemandeDevisComponent } from './Components/list-demande-devis/list-demande-devis.component';
import { ReponseDevisComponent } from './Components/reponse-devis/reponse-devis.component';
import { ReponseDevisCodeComponent } from './Components/reponse-devis-code/reponse-devis-code.component';
import { AddResponseDevisComponent } from './Components/add-response-devis/add-response-devis.component';
import { ConfirmDevisComponent } from './Components/confirm-devis/confirm-devis.component';
import { AddMissionComponent } from './Components/add-mission/add-mission.component';
import { MissionTransporteurComponent } from './Components/mission-transporteur/mission-transporteur.component';
import { StatistiqueMissionComponent } from './Components/statistique-mission/statistique-mission.component';
import { MissionChauffeurComponent } from './Components/mission-chauffeur/mission-chauffeur.component';
import { HowDoesItWorkComponent } from './Components/how-does-it-work/how-does-it-work.component';
import { LandingPageComponent } from './Components/landing-page/landing-page.component';
import { ListTransporteursComponent } from './Components/list-transporteurs/list-transporteurs.component';

import { MissionHistoryComponent } from './Components/mission-history/mission-history.component';
import { ContactComponent } from './Components/contact/contact.component';
import { ProfilComponent } from './Components/profil/profil.component';
import { FooterComponent } from './Components/footer/footer.component';
import { MessageTransporteurComponent } from './Components/message-transporteur/message-transporteur.component';
import { MessageChauffeurComponent } from './Components/message-chauffeur/message-chauffeur.component';
import { GuideComponent } from './Components/guide/guide.component';
import { CreateMissionWithDevisComponent } from './Components/create-mission-with-devis/create-mission-with-devis.component';



const routes: Routes = [
  {
    path: '',
    component: LandingPageComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'home-page',
    component: HomePageComponent,
  },
  {
    path: 'test',
    component: TestComponent,
  },
  {
    path: 'list-chauffeur',
    component: ChouffeurListComponent,
  },
  {
    path: 'form-chauffeur',
    component: ChouffeurFormComponent,
  },
  {
    path: 'update-chauffeur/:id',
    component: UpdateChouffeurComponent,
  },
  {
    path: 'upload-file',
    component: FileUploadComponent,
  },
  {
    path: 'details-chauffeur/:id',
    component: DetailsChauffeurComponent,
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'vehicules',
    component: VehiculesComponent,
  },
  {
    path: 'statistiques-vehicules',
    component: StatistiquesVehiculesComponent,
  },
  {
    path: 'devis-client',
    component: DevisFormClientComponent,
  },
  {
    path: 'list-devis-transporteur',
    component: ListDemandeDevisComponent,
  },
  {
    path: 'reponse-devis',
    component: ReponseDevisComponent,
  },
  {
    path: 'reponse-devis/:codeDevis',
    component: ReponseDevisCodeComponent,
  },
  {
    path: 'add-reponse-devis/:id',
    component: AddResponseDevisComponent,
  },
  {
    path: 'confirm-devis',
    component: ConfirmDevisComponent,
  },
  {
    path: 'add-mission/:id',
    component: AddMissionComponent,
  },
  {
    path: 'mission-transporteur',
    component: MissionTransporteurComponent,
  },
  {
    path: 'statistique-mission',
    component: StatistiqueMissionComponent,
  },
  {
    path: 'mission-chauffeur',
    component: MissionChauffeurComponent,
  },
  {
    path: 'comment-ca-marche',
    component: HowDoesItWorkComponent,
  },
  {
    path: 'transporteurs',
    component: ListTransporteursComponent,
  },
  {
    path: 'mission-history/:id',
    component: MissionHistoryComponent,
  },
  {
    path: 'contact',
    component: ContactComponent,
  },
  {
    path: 'profil',
    component: ProfilComponent,
  },
  {
    path: 'message-transporteur',
    component: MessageTransporteurComponent,
  },
  {
    path: 'message-chauffeur',
    component: MessageChauffeurComponent,
  },
  {
    path: 'guide',
    component: GuideComponent,
  },
  {
    path: 'create-mission-with-devis',
    component: CreateMissionWithDevisComponent,
  }
  
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
