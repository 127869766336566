import { Component, OnInit } from '@angular/core';
import { MissionService } from 'src/app/Services/mission.service';

@Component({
  selector: 'app-statistique-mission',
  templateUrl: './statistique-mission.component.html',
  styleUrls: ['./statistique-mission.component.css']
})
export class StatistiqueMissionComponent implements OnInit {

  missions: any[] = [];
  filteredMissions: any[] = [];
  statusPercentages: { [key: string]: number } = {};
  totalMissions: number = 0;

  // Critères de filtrage
  startDate: string = '';
  endDate: string = '';
  statusFilter: string = '';

  statuses = [
    'Prise en charge marchandise',
    'Marchandise livrée',
    'Revenu au parking',
    'Mission démarrée',
    'Mission créée',
    'Retourner au parking'
  ];

  constructor(private missionService: MissionService) { }

  ngOnInit(): void {
    const idTranspoteur = Number(localStorage.getItem('idUser'));
    this.missionService.getMissions().subscribe(data => {
      this.missions = data.filter(mission => mission.idTranspoteur === idTranspoteur);
      this.filterMissions(); // Filtrer initialement
    });
  }

  filterMissions(): void {
    this.filteredMissions = this.missions.filter(mission => {
      const missionDateDebut = new Date(mission.dateDebut);
      const missionDateFin = new Date(mission.dateFin);

      // Vérifier les dates
      const startDateValid = this.startDate ? missionDateDebut >= new Date(this.startDate) : true;
      const endDateValid = this.endDate ? missionDateFin <= new Date(this.endDate) : true;
      
      // Vérifier le statut
      const statusValid = this.statusFilter ? mission.statut === this.statusFilter : true;

      return startDateValid && endDateValid && statusValid;
    });

    this.calculateStatusPercentages(); // Recalculer les pourcentages après filtrage
  }

  calculateStatusPercentages() {
    this.totalMissions = this.filteredMissions.length;
    const statusCounts: { [key: string]: number } = {};

    this.statuses.forEach(status => {
      statusCounts[status] = 0;
    });

    this.filteredMissions.forEach(mission => {
      const status = mission.statut;
      if (statusCounts.hasOwnProperty(status)) {
        statusCounts[status]++;
      }
    });

    for (const status of this.statuses) {
      this.statusPercentages[status] = this.totalMissions > 0 ? (statusCounts[status] / this.totalMissions) * 100 : 0;
    }
  }

  getPercentage(status: string): number {
    return this.statusPercentages[status] || 0;
  }

  getColor(status: string): string {
    switch(status) {
      case 'Prise en charge marchandise': return '#ff5722'; // Orange
      case 'Marchandise livrée': return '#4caf50'; // Vert
      case 'Revenu au parking': return '#2196f3'; // Bleu
      case 'Mission démarrée': return '#9c27b0'; // Violet
      default: return '#9e9e9e'; // Gris
    }
  }

  get filteredStatuses() {
    return this.statuses.filter(status => 
      this.filteredMissions.some(mission => mission.statut === status)
    );
  }
}
