<!DOCTYPE html>
<html lang="fr">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Comment ça marche - Gestion de Transport de Marchandises</title>
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap" rel="stylesheet">
    <link rel="stylesheet" href="">
</head>
<body>
    <header>
        <div class="container">
            <h1>Guide d'utilisation</h1>
            <p>Votre guide complet pour naviguer et exploiter pleinement notre plateforme de gestion de transport de marchandises.</p>
            <hr>
            <p>Notre solution simplifie la gestion du transport pour tous les acteurs impliqués : transporteurs, clients et chauffeurs. Découvrez comment optimiser vos opérations et tirer le meilleur parti de notre plateforme.</p>
        </div>
    </header>

    <!-- Section pour Transporteurs -->
    <section id="transporteurs" class="transporteurs-section">
        <div class="container">
            <h2>Pour les Transporteurs</h2>
            <div class="grid">
                <div class="feature">
                    <img src="transporteur-image-1.jpg" alt="Gestion des véhicules" class="feature-img">
                    <h3>Gestion du parc de véhicules</h3>
                    <p>Ajoutez et gérez facilement votre parc de véhicules grâce à notre interface intuitive. Suivez les réparations, les entretiens et les coûts associés. Utilisez les alertes d'entretien prédictif pour éviter les pannes imprévues et maximiser l'utilisation de vos véhicules.</p>
                </div>
                <div class="feature">
                    <img src="transporteur-image-2.jpg" alt="Gestion des chauffeurs" class="feature-img">
                    <h3>Gestion des chauffeurs</h3>
                    <p>Intégrez vos chauffeurs dans notre système en quelques clics. Suivez leurs performances grâce à des indicateurs clés, gérez leurs plannings et optimisez leur emploi du temps. Profitez de nos outils de formation en ligne pour améliorer les compétences de vos chauffeurs.</p>
                </div>
                <div class="feature">
                    <img src="transporteur-image-3.jpg" alt="Suivi des missions" class="feature-img">
                    <h3>Suivi des missions</h3>
                    <p>Visualisez l'avancement des missions en temps réel. Recevez des mises à jour instantanées sur les statuts des missions et des véhicules. Communiquez directement avec vos chauffeurs et ajustez les missions en fonction des besoins de dernière minute.</p>
                </div>
            </div>
        </div>
    </section>

    <!-- Section pour Clients -->
    <section id="clients" class="clients-section">
        <div class="container">
            <h2>Pour les Clients</h2>
            <div class="grid">
                <div class="feature">
                    <img src="client-image-1.jpg" alt="Demande de devis" class="feature-img">
                    <h3>Demande de devis</h3>
                    <p>Obtenez des devis détaillés et comparez les offres de différents transporteurs. Notre plateforme permet une demande de devis rapide et facile, avec la possibilité de comparer les tarifs, les délais et les services proposés.</p>
                </div>
                <div class="feature">
                    <img src="client-image-2.jpg" alt="Suivi des marchandises" class="feature-img">
                    <h3>Suivi des marchandises</h3>
                    <p>Suivez vos marchandises en temps réel grâce à des cartes interactives et des notifications. Recevez des mises à jour sur l'état de vos envois et prévoyez les horaires de livraison avec précision grâce à notre technologie de suivi avancée.</p>
                </div>
                <div class="feature">
                    <img src="client-image-3.jpg" alt="Historique des commandes" class="feature-img">
                    <h3>Historique des commandes</h3>
                    <p>Consultez l'historique complet de vos commandes, gérez vos factures et accédez à des outils de recherche avancés pour retrouver rapidement des informations spécifiques. Organisez vos commandes passées pour une gestion simplifiée.</p>
                </div>
            </div>
        </div>
    </section>

    <!-- Section pour Chauffeurs -->
    <section id="chauffeurs" class="chauffeurs-section">
        <div class="container">
            <h2>Pour les Chauffeurs</h2>
            <div class="grid">
                <div class="feature">
                    <img src="chauffeur-image-1.jpg" alt="Réception des missions" class="feature-img">
                    <h3>Réception des missions</h3>
                    <p>Recevez vos missions directement sur l'application mobile. Visualisez les itinéraires, gérez vos horaires et recevez des notifications pour chaque nouvelle mission ou mise à jour importante.</p>
                </div>
                <div class="feature">
                    <img src="chauffeur-image-2.jpg" alt="Mise à jour des missions" class="feature-img">
                    <h3>Mise à jour des missions</h3>
                    <p>Modifiez l'état des missions en temps réel, signalez tout incident ou retard directement depuis votre application. Communiquez instantanément avec les gestionnaires et accédez à des outils de reporting pour faciliter la gestion de votre travail.</p>
                </div>
                <div class="feature">
                    <img src="chauffeur-image-3.jpg" alt="Suivi du véhicule" class="feature-img">
                    <h3>Suivi du véhicule</h3>
                    <p>Gardez un œil sur l'état de votre véhicule avec des alertes d'entretien préventif. Recevez des recommandations personnalisées pour améliorer la performance du véhicule et suivre les aspects de sécurité et de maintenance.</p>
                </div>
            </div>
        </div>
    </section>

    <!-- Section pour Missions -->
    <section id="missions" class="missions-section">
        <div class="container">
            <h2>Gestion des Missions</h2>
            <div class="grid">
                <div class="feature">
                    <img src="mission-image-1.jpg" alt="Création de missions" class="feature-img">
                    <h3>Création de missions</h3>
                    <p>Créez des missions détaillées en quelques étapes. Définissez les critères, attribuez-les aux chauffeurs, et personnalisez les exigences selon vos besoins spécifiques. Utilisez les modèles de missions pour simplifier le processus.</p>
                </div>
                <div class="feature">
                    <img src="mission-image-2.jpg" alt="Suivi des missions" class="feature-img">
                    <h3>Suivi des missions</h3>
                    <p>Suivez l'avancement des missions avec des outils de gestion avancés. Gérez les imprévus en temps réel, ajustez les missions en fonction des conditions et accédez à des rapports détaillés pour une meilleure gestion des opérations.</p>
                </div>
                <div class="feature">
                    <img src="mission-image-3.jpg" alt="Rapports et analyses" class="feature-img">
                    <h3>Rapports et analyses</h3>
                    <p>Générez des rapports détaillés sur l'efficacité des missions et analysez les données pour identifier les opportunités d'amélioration. Utilisez nos outils de BI pour obtenir des insights précieux et optimiser vos opérations.</p>
                </div>
            </div>
        </div>
    </section>


    <!-- Vidéo d'introduction -->
    <section id="video" class="video-section">
        <div class="container">
            <h2>Découvrez notre plateforme en vidéo</h2>
            <video controls>
                <source src="intro-video.mp4" type="video/mp4">
                Votre navigateur ne supporte pas la vidéo.
            </video>
        </div>
    </section>

    <!-- Footer -->
    <footer>
        <div class="container">
            <p>Pour plus d'informations, <a href="contact.html">contactez-nous</a> ou demandez une <a href="demo.html">démonstration</a>.</p>
            <p>Suivez-nous sur <a href="https://facebook.com/yourcompany" target="_blank">Facebook</a>, <a href="https://twitter.com/yourcompany" target="_blank">Twitter</a>, et <a href="https://linkedin.com/company/yourcompany" target="_blank">LinkedIn</a>.</p>
            <p>&copy; 2024 Votre Société. Tous droits réservés.</p>
        </div>
    </footer>
</body>
</html>

