import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactService } from 'src/app/Services/contact.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;

  constructor(private fb: FormBuilder, private contactService: ContactService) {
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required]
    });
  }

  ngOnInit(): void {}

  submitContactForm(): void {
    if (this.contactForm.valid) {
      const contact = this.contactForm.value;

      this.contactService.sendContactForm(contact).subscribe(
        response => {
          Swal.fire({
            title: 'Succès!',
            text: 'Votre message a été envoyé.',
            icon: 'success',
            confirmButtonText: 'OK'
          });
          this.contactForm.reset(); // Réinitialiser le formulaire après envoi
        },
        error => {
          Swal.fire({
            title: 'Erreur!',
            text: 'Une erreur est survenue lors de l\'envoi du message.',
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      );
    } else {
      Swal.fire({
        title: 'Erreur!',
        text: 'Veuillez remplir tous les champs correctement.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  }
}
