import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReponseDevisService {


  //private apiUrl = 'http://localhost:9067/api/reponseDevis';
  private apiUrl = environment.APP_API_URL +"/api/reponseDevis";

  private httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }

  getReponseDevis(): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl);
  }

  getReponseDevisById(id: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/${id}`);
  }

  createReponseDevis(devis: any): Observable<any> {
    return this.http.post<any>(this.apiUrl, devis, this.httpOptions);
  }

  updateReponseDevis(devis: any): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/${devis.id}`, devis, this.httpOptions);
  }

  deleteReponseDevis(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`, this.httpOptions);
  }

  getResponseByCodeDevis(code: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/byCodeDevis/${code}`);
  }
}
