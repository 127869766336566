import { Component, OnInit } from '@angular/core';
import { VehiculeService } from 'src/app/Services/vehicule.service';

@Component({
  selector: 'app-statistiques-vehicules',
  templateUrl: './statistiques-vehicules.component.html',
  styleUrls: ['./statistiques-vehicules.component.css']
})
export class StatistiquesVehiculesComponent implements OnInit {

  percentages: { [key: string]: number } = {};
  totalVehicule: number = 0;
  constructor(private vehiculeService: VehiculeService) { }

  ngOnInit(): void {
    this.fetchData();
    this.getTotalVehicles();
  }

  fetchData(): void {
    this.vehiculeService.getVehicules().subscribe((data: any[]) => {
      if (data && data.length > 0) {
        const total = data.length;
        const statusCounts = data.reduce((acc, vehicule) => {
          acc[vehicule.statut] = (acc[vehicule.statut] || 0) + 1;
          return acc;
        }, {} as { [key: string]: number });

        this.percentages = {
          'Disponible': (statusCounts['Disponible'] || 0) / total * 100,
          'Non disponible': (statusCounts['Non disponible'] || 0) / total * 100,
          'En panne': (statusCounts['En panne'] || 0) / total * 100,
          'Document non valable': (statusCounts['Document non valable'] || 0) / total * 100,
          'En maintenance': (statusCounts['En maintenance'] || 0) / total * 100,
          'En cours de réparation': (statusCounts['En cours de réparation'] || 0) / total * 100,
          'Hors service': (statusCounts['Hors service'] || 0) / total * 100,
          'En transit': (statusCounts['En transit'] || 0) / total * 100,
          'En attente d\'inspection': (statusCounts['En attente d\'inspection'] || 0) / total * 100,
        };
        console.log('Calculated percentages:', this.percentages);
      } else {
        console.warn('No data received from the API');
      }
    }, error => {
      console.error('Error fetching data from API', error);
    });
  }

  getColor(status: string): string {
    const colors: { [key: string]: string } = {
      'Disponible': '#5EB344',
      'Non disponible': '#FF6347',
      'En panne': '#FF4500',
      'Document non valable': '#FCB72A',
      'En maintenance': '#F8821A',
      'En cours de réparation': '#FF8C00',
      'Hors service': '#E0393E',
      'En transit': '#963D97',
      'En attente d\'inspection': '#069CDB'
    };
    return colors[status] || '#000000'; // Default color
  }

  getStatuses(): string[] {
  return [
    'Disponible',
    'Non disponible',
    'En panne',
    'Document non valable',
    'En maintenance',
    'En cours de réparation',
    'Hors service',
    'En transit',
    'En attente d\'inspection'
  ];
}
getTotalVehicles(): void {
  this.vehiculeService.getVehicules().subscribe((data: any[]) => {
    if (data && data.length > 0) {
      this.totalVehicule = data.length;
    } else {
      this.totalVehicule = 0; // Handle the case when there are no vehicles
    }
  });
}

}
