import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImageUploadService {
 // private baseUrl = 'http://localhost:9067/image';

  private baseUrl = environment.APP_API_URL +"/image";

  constructor(private httpClient: HttpClient) { }

  /*uploadImage(imageData: FormData): Observable<any> {
    return this.httpClient.post(`${this.baseUrl}/upload`, imageData, { observe: 'response' });
  }*/

    uploadImage(imageData: FormData, userId: number): Observable<any> {
      return this.httpClient.post(`${this.baseUrl}/upload/${userId}`, imageData, { observe: 'response' });
    }

  getImage(imageName: string): Observable<any> {
    return this.httpClient.get(`${this.baseUrl}/get/${imageName}`);
  }


  getImageNamesByUserId(userId: number): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.baseUrl}/user/${userId}/names`);
  }
}
