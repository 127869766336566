<div class="container">
    <h1>Image Upload and Display</h1>
  
    <div class="form-group">
      <input type="file" (change)="onFileChanged($event)" />
    </div>
  
    <button (click)="onUpload()">Upload Image</button>
  
    <div *ngIf="message">
      <p>{{ message }}</p>
    </div>
  
    <div class="form-group">
      <input type="text" [(ngModel)]="imageName" placeholder="Enter image name to retrieve" />
    </div>
  
    <button (click)="getImage()">Get Image</button>
  
    <div *ngIf="retrievedImage">
      <h2>Retrieved Image</h2>
      <img [src]="retrievedImage" />
    </div>
  </div>
  