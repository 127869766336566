 <div class="login-container">
    <div class="login-wrapper">
      <div class="login-form">
        <h2>Connexion</h2>
        <p>Pas encore inscrit? <a routerLink="/register">Créez un compte ici</a>.</p>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label for="username">Nom d'utilisateur</label>
            <div class="input-container">
              <input
                class="input--style-5 form-control"
                type="text"
                id="username"
      
                name="username"
                formControlName="userName" placeholder="Entrer votre nom"
                required
              />
            </div>
          </div>
          <div class="form-group">
            <label for="password">Mot de passe</label>
            <div class="input-container">
              <input
                class="input--style-5 form-control"
                type="password"
                id="password"
          
                name="password"
                formControlName="password" 
                placeholder="Entrer votre mot de passe"
                required
              />
            </div>
          </div>
          <div class="button-container">
            <button type="submit">Se connecter</button>
          </div>
        </form>
        <p><a href="/reset-password">Mot de passe oublié ?</a></p>
      </div>
      <div class="login-image">
        <img src="../assets/img.jpg" alt="Login Image">
      </div>
    </div>
  </div>
  
  
