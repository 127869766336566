<div class="container">
<div class="row mb-5">
  <div class="col">
    <input type="text" [(ngModel)]="searchText" class="form-control search-input" placeholder="Rechercher..." />
  </div>
  <div class="col text-end">
    Total d'éléments : <span class="badge">{{ devisList.length }}</span>
  </div>
</div>

<div *ngIf="devisList.length > 0; else noDevis">
  <div class="row">
    <ng-container *ngFor="let devis of devisList | paginate: { itemsPerPage: 6, currentPage: page, totalItems: totalLength }  | filter:searchText">
      <div class="col-md-4 mb-4">
        <div class="card h-100">
          <div class="card-header d-flex justify-content-between align-items-center">
            <h5 class="card-title mb-0">Information Devis</h5>
            <span class="badge rounded-pill">{{ devis.id }}</span>
          </div>
          <div class="card-body">
            <p class="mb-1 text-muted-small"><strong>Nom Client:</strong> {{ devis.nomClient }}</p>
            <p class="mb-1 text-muted-small"><strong>Adresse Client:</strong> {{ devis.adresseClient }}</p>
            <h6 class="text-muted mb-3">Information Devis</h6>
            <hr>
            <p class="mb-1 text-muted-small"><strong>Ville Départ:</strong> {{ devis.villeDepart }}</p>
            <p class="mb-1 text-muted-small"><strong>Ville Arrivée:</strong> {{ devis.villeArrivee }}</p>
            <p class="mb-1 text-muted-small"><strong>Date Livraison Souhaitée:</strong> {{ devis.dateLivraisonSouhaitee | date: 'shortDate' }}</p>
            <p class="mb-1 text-muted-small"><strong>Créé le:</strong> {{ devis.createdAt | date: 'short' }}</p>
          </div>
          <div class="card-footer">
            <a [href]="'add-reponse-devis/' + devis.id" class="btn"><i class="fa fa-plus-square-o" aria-hidden="true"></i> Réponse</a>
            <button (click)="showDetails(devis)" class="btn"> <i class="fa fa-id-card-o" aria-hidden="true"></i> Détails</button>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="d-flex justify-content-center mt-3">
      <pagination-controls (pageChange)="page = $event"></pagination-controls>
    </div>
  </div>
</div>
<ng-template #noDevis>
  <p>Aucun devis disponible.</p>
</ng-template>
</div>