import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MissionHistoryService } from 'src/app/Services/mission-history.service';

@Component({
  selector: 'app-mission-history',
  templateUrl: './mission-history.component.html',
  styleUrls: ['./mission-history.component.css']
})
export class MissionHistoryComponent implements OnInit {

  idMission: number | undefined;
  missions: any[] = [];
  missionHistory: any[] = [];
  filteredMission: any | undefined;
  chauffeur: any | undefined; // Ajouter une propriété pour les détails du chauffeur

  constructor(private route: ActivatedRoute, private missionHistoryService: MissionHistoryService) { }

  ngOnInit(): void {
    // Obtenez l'ID de la mission à partir des paramètres de la route
    this.route.params.subscribe(params => {
      this.idMission = +params['id']; // Assurez-vous que l'ID est un nombre
      this.loadMissionsAndHistory();
    });
  }

  // Charger les missions, l'historique des missions et les détails du chauffeur
  loadMissionsAndHistory(): void {
    this.missionHistoryService.getMissions().subscribe(
      (missionsData) => {
        this.missions = missionsData;
        if (this.idMission) {
          this.filteredMission = this.missions.find(mission => mission.id === this.idMission);
          if (this.filteredMission) {
            this.loadChauffeur(this.filteredMission.idChauffeur); // Charger les détails du chauffeur
          }
        }
        this.loadMissionHistory();
      },
      (error) => {
        console.error('Erreur lors de la récupération des missions:', error);
      }
    );
  }

  // Charger l'historique des missions
  loadMissionHistory(): void {
    this.missionHistoryService.getMissionHistory().subscribe(
      (historyData) => {
        this.missionHistory = historyData;
        // Associez l'historique aux missions filtrées
        if (this.filteredMission) {
          this.filteredMission.history = this.missionHistory.filter(history => history.idMission === this.filteredMission.id);
        }
      },
      (error) => {
        console.error('Erreur lors de la récupération de l\'historique des missions:', error);
      }
    );
  }

  // Charger les détails du chauffeur
  loadChauffeur(idChauffeur: number): void {
    this.missionHistoryService.getChauffeur(idChauffeur).subscribe(
      (chauffeurData) => {
        this.chauffeur = chauffeurData;
      },
      (error) => {
        console.error('Erreur lors de la récupération des détails du chauffeur:', error);
      }
    );
  }
}
