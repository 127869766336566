import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-how-does-it-work',
  templateUrl: './how-does-it-work.component.html',
  styleUrls: ['./how-does-it-work.component.css']
})
export class HowDoesItWorkComponent implements OnInit {
  sections = [
    {
      number: 1,
      title: 'Choix du service',
      description: 'Pour commencer, remplissez un formulaire spécifiant le type de service demandé : tout types de service ou LTL ou chargement complet ou petits colis.',
      imageUrl: './assets/guide/step-2.png'
    },
    {
      number: 2,
      title: 'Ramassage',
      description: 'Vous devez remplir un formulaire indiquant l\'adresse de ramassage, le type de lieu et des détails supplémentaires.',
      imageUrl: './assets/guide/step-3.png' 
    },
    {
      number: 3,
      title: 'Services Supplémentaires',
      description: 'À ce stade, vous pouvez remplir un formulaire pour préciser les besoins en services supplémentaires comme une collecte intérieure.',
      imageUrl: './assets/guide/step-4.png' 
    },
    {
      number: 4,
      title: 'Date de Prélèvement',
      description: 'Vous devez spécifier la date de prélèvement de votre marchandise.',
      imageUrl: './assets/guide/step-5.png'
    },
    {
      number: 5,
      title: 'Détails d\'Expédition Requis',
      description: 'Formulaire pour spécifier le pays, le code postal et le type de lieu de destination de votre marchandise.',
      imageUrl: './assets/guide/step-6.png'
    },
    {
      number: 6,
      title: 'Options de Livraison',
      description: 'Formulaire pour sélectionner des services supplémentaires à la livraison.',
      imageUrl: './assets/guide/step-7.png' 
    },
    {
      number: 7,
      title: 'Détails de l\'expédition',
      description: 'Formulaire pour spécifier la description, le type d\'emballage, dimensions et poids des articles expédiés.',
      imageUrl: './assets/guide/step-8.png' 
    }
  ];
  

  ngOnInit() {
    console.log(this.sections);
  }

  openPopup(number: number) {
    const section = this.sections.find(section => section.number === number);
  
    if (section) {
      Swal.fire({
        title: section.title,
        text: section.description,
        imageUrl: section.imageUrl,
        imageAlt: section.title,
        confirmButtonText: 'Fermer',
        width: '700px' 
      });
    }
  }
  
  
  constructor() { }


}
