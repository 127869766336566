<div class="contact-container">
    <h1 style="margin-top:2rem; margin-bottom: 2rem; text-align: center;font-family: 'Open Sans',sans-serif; font-weight: 900; color: #002e51;">Contactez-nous</h1>
    
    <p style="color: gray;  text-align: center;">
      Si vous avez envoyé ce formulaire, il sera examiné par l'administrateur de ce site.
    </p>
    <hr style="width: 20rem;  margin: 15px auto;">
    <form [formGroup]="contactForm" (ngSubmit)="submitContactForm()" class="contact-form">
      <div class="form-group">
        <label for="name">Nom:</label>
        <input id="name" formControlName="name" placeholder="Entrez votre nom" />
        <div *ngIf="contactForm.get('name')?.invalid && (contactForm.get('name')?.dirty || contactForm.get('name')?.touched)" class="error-message">
          <small *ngIf="contactForm.get('name')?.errors?.['required']">Le nom est requis.</small>
        </div>
      </div>
  
      <div class="form-group">
        <label for="email">Email:</label>
        <input id="email" formControlName="email" type="email" placeholder="Entrez votre email" />
        <div *ngIf="contactForm.get('email')?.invalid && (contactForm.get('email')?.dirty || contactForm.get('email')?.touched)" class="error-message">
          <small *ngIf="contactForm.get('email')?.errors?.['required']">L'email est requis.</small>
          <small *ngIf="contactForm.get('email')?.errors?.['email']">L'email est invalide.</small>
        </div>
      </div>
  
      <div class="form-group">
        <label for="message">Message:</label>
        <textarea id="message" formControlName="message" placeholder="Votre message"></textarea>
        <div *ngIf="contactForm.get('message')?.invalid && (contactForm.get('message')?.dirty || contactForm.get('message')?.touched)" class="error-message">
          <small *ngIf="contactForm.get('message')?.errors?.['required']">Le message est requis.</small>
        </div>
      </div>
  
      <button type="submit" class="submit-button">Envoyer</button>
    </form>
  </div>
  