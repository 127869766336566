import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DevisService {

  // private apiUrl = 'http://localhost:9067/api/devis';
  private apiUrl = environment.APP_API_URL +"/api/devis";
  private httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }

  getDevis(): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl);
  }

  getDevisById(id: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/${id}`);
  }

  createDevis(devis: any): Observable<any> {
    return this.http.post<any>(this.apiUrl, devis, this.httpOptions);
  }
  

  updateDevis(devis: any): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/${devis.id}`, devis, this.httpOptions);
  }

  deleteDevis(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`, this.httpOptions);
  }


  getDevisByCodeReponseDevis(codeReponseDevis: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/code/${codeReponseDevis}`).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // Erreur côté client
      console.error('An error occurred:', error.error.message);
    } else {
      // Erreur côté serveur
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }
}
