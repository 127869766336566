import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmDevisService } from 'src/app/Services/confirm-devis.service';
import { DevisService } from 'src/app/Services/devis.service';
import { ReponseDevisService } from 'src/app/Services/reponse-devis.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reponse-devis',
  templateUrl: './reponse-devis.component.html',
  styleUrls: ['./reponse-devis.component.css']
})
export class ReponseDevisComponent implements OnInit {

  reponseDevisForm: FormGroup;
  reponseDevis: any = null;
  devis: any = null;
  errorMessage: string | null = null;

  constructor(
    private fb: FormBuilder,
    private reponseDevisService: ReponseDevisService,
    private devisService: DevisService, private confirmDevisService: ConfirmDevisService
  ) {
    this.reponseDevisForm = this.fb.group({
      codeReponseDevis: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    // Initial setup if needed
  }

 
  onSubmit() {
    const code = this.reponseDevisForm.get('codeReponseDevis')!.value;
    this.devisService.getDevisByCodeReponseDevis(code).subscribe(
      data => {
        this.devis = data;
        console.log("devis : ", this.devis);
        this.errorMessage = null;
        this.fetchReponseDevisByCode(code);
      },
      error => {
        console.error('Erreur lors de la récupération du devis', error);
        this.devis = null;
        this.errorMessage = 'Devis non trouvé';
        this.reponseDevis = null;
      }
    );
  }

  fetchReponseDevisByCode(code: string) {
    this.reponseDevisService.getResponseByCodeDevis(code).subscribe(
      data => {
        this.reponseDevis = data;
        console.log("response devis : ", this.reponseDevis);
        this.errorMessage = null;
      },
      error => {
        console.error('Erreur lors de la récupération de la réponse de devis', error);
        this.reponseDevis = null;
        if (this.devis) {
          this.errorMessage = 'Pas de réponse pour le moment';
        }
      }
    );
  }
  confirm(resDevis: any) {
    const newObj = {
      devis: resDevis.devis,
      user: resDevis.user
    };

    // Utilisation de SweetAlert2 pour afficher une alerte modale
    Swal.fire({
      title: 'Confirmer',
      text: 'Êtes-vous sûr de vouloir envoyer cette confirmation de devis?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmer',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.isConfirmed) {
        // Appel du service pour créer la confirmation du devis
        this.confirmDevisService.createConfirmDevis(newObj).subscribe(
          (response: any) => {
            Swal.fire('Succès', 'Confirmation au devis envoyée avec succès!', 'success');
          },
          (error: any) => {
            Swal.fire('Erreur', 'Erreur lors de l\'enregistrement de la réponse au devis.', 'error');
          }
        );
      }
    });
  }
}


