<!-- mission-history.component.html -->
<div *ngIf="filteredMission" class="container">
  
  <!-- Section Détails de la Mission -->
  <div class="section">
    <h2>Détails de la Mission</h2>
    <div class="card">
      <table>
        <tbody>
          <tr>
            <th>ID</th>
            <td>{{ filteredMission.id }}</td>
          </tr>
          <tr>
            <th>Date de début</th>
            <td>{{ filteredMission.dateDebut }}</td>
          </tr>
          <tr>
            <th>Date de fin</th>
            <td>{{ filteredMission.dateFin }}</td>
          </tr>
          <tr>
            <th>Description</th>
            <td>{{ filteredMission.description }}</td>
          </tr>
          <tr>
            <th>Statut actuel</th>
            <td><span class="badge-status">{{ filteredMission.statut }}</span></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Section Historique de la Mission -->
  <div class="section">
    <h3>Historique de la Mission</h3>
    <div class="card">
      <div class="list-item" *ngFor="let history of filteredMission?.history">
        <strong>Date:</strong> {{ history.createdAt }} |
        <strong>Statut:</strong>
        <span [ngClass]="{
          'status-prise-en-charge': history.statut === 'Prise en charge marchandise',
          'status-livree': history.statut === 'Marchandise livrée',
          'status-revenu-parking': history.statut === 'Revenu au parking',
          'status-mission-demarree': history.statut === 'Mission démarrée',
          'status-retour-parking': history.statut === 'Retourner au parking'
        }">{{ history.statut }}</span>
      </div>
    </div>
  </div>

  <!-- Section Détails du Véhicule -->
  <div class="section">
    <h3>Détails du Véhicule</h3>
    <div class="card">
      <table>
        <tbody>
          <tr>
            <th>Type</th>
            <td>{{ filteredMission.vehicule.type }}</td>
          </tr>
          <tr>
            <th>Immatriculation</th>
            <td>{{ filteredMission.vehicule.immatriculation }}</td>
          </tr>
          <tr>
            <th>Statut</th>
            <td>{{ filteredMission.vehicule.statut }}</td>
          </tr>
          <tr>
            <th>Marque</th>
            <td>{{ filteredMission.vehicule.marque }}</td>
          </tr>
          <tr>
            <th>Modèle</th>
            <td>{{ filteredMission.vehicule.modele }}</td>
          </tr>
          <tr>
            <th>Année</th>
            <td>{{ filteredMission.vehicule.annee }}</td>
          </tr>
          <tr>
            <th>Capacité</th>
            <td>{{ filteredMission.vehicule.capacite }}</td>
          </tr>
          <tr>
            <th>Kilométrage</th>
            <td>{{ filteredMission.vehicule.kilometrage }}</td>
          </tr>
          <tr>
            <th>Type de carburant</th>
            <td>{{ filteredMission.vehicule.typeCarburant }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Section Détails du Chauffeur -->
  <div class="section">
    <h3>Détails du Chauffeur</h3>
    <div class="card">
      <table>
        <tbody>
          <tr>
            <th>ID</th>
            <td>{{ chauffeur.id }}</td>
          </tr>
          <tr>
            <th>Nom</th>
            <td>{{ chauffeur.firstName }} {{ chauffeur.lastName }}</td>
          </tr>
          <tr>
            <th>Email</th>
            <td>{{ chauffeur.email }}</td>
          </tr>
          <tr>
            <th>Téléphone</th>
            <td>{{ chauffeur.phoneNumber }}</td>
          </tr>
          <tr>
            <th>Adresse</th>
            <td>{{ chauffeur.address }}, {{ chauffeur.city }}, {{ chauffeur.postalCode }}</td>
          </tr>
          <tr>
            <th>Date de naissance</th>
            <td>{{ chauffeur.dateOfBirth }}</td>
          </tr>
          <tr>
            <th>Type de véhicule</th>
            <td>{{ chauffeur.vehicleType }}</td>
          </tr>
          <tr>
            <th>Années d'expérience</th>
            <td>{{ chauffeur.experienceYears }} ans</td>
          </tr>
          <tr>
            <th>Évaluations</th>
            <td>{{ chauffeur.ratings }}</td>
          </tr>
          <tr>
            <th>Permis de conduire</th>
            <td>{{ chauffeur.drivingLicenseCategory }}</td>
          </tr>
          <tr>
            <th>Contact d'urgence</th>
            <td>{{ chauffeur.emergencyContactPhone }}</td>
          </tr>
          <tr>
            <th>Statut marital</th>
            <td>{{ chauffeur.maritalStatus }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
