import { Component, OnInit } from '@angular/core';
import { MissionService } from 'src/app/Services/mission.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-mission-chauffeur',
  templateUrl: './mission-chauffeur.component.html',
  styleUrls: ['./mission-chauffeur.component.css']
})
export class MissionChauffeurComponent implements OnInit {
  missions: any[] = [];
  searchText='';
  page: number = 1;
  totalLength: number = 0;

  statuts = ['Mission démarrée','Prise en charge marchandise', 'Marchandise livrée', 'Retourner au parking'];

  constructor(private missionService: MissionService) { }

  ngOnInit(): void {
    this.loadMissions();
  }

  loadMissions(): void {
    // Récupérer l'ID du Chauffeur depuis le localStorage
    const idChauffeur = Number(localStorage.getItem('idUser'));

    this.missionService.getMissions().subscribe(data => {
      this.missions = data.filter(mission => mission.idChauffeur === idChauffeur);
    }, error => {
      console.error('Erreur lors de la récupération des missions', error);
    });
  }

  sortByDateDebut(): void {
    this.missions.sort((a, b) => {
      const dateA = new Date(a.dateDebut);
      const dateB = new Date(b.dateDebut);
      return dateA.getTime() - dateB.getTime();
    });
  }

  sortByDateFin(): void {
    this.missions.sort((a, b) => {
      const dateA = new Date(a.dateFin);
      const dateB = new Date(b.dateFin);
      return dateA.getTime() - dateB.getTime();
    });
  }

  getStatusClass(status: string): string {
    switch (status) {
      case 'Prise en charge marchandise':
        return 'badge-primary'; // Bleu pour "Prise en charge marchandise"
      case 'Marchandise livrée':
        return 'badge-success'; // Vert pour "Marchandise livrée"
      case 'Revenu au parking':
        return 'badge-warning'; // Jaune pour "Revenu au parking"
      case 'Mission démarrée':
        return 'badge-info'; // Cyan pour "Mission démarrée"
      case 'Mission créée':
        return 'badge-danger'; // Rouge pour "Mission créée"
      default:
        return 'badge-light'; // Couleur par défaut pour les statuts inconnus
    }
  }

  openStatusEditPopup(mission: any) {
    Swal.fire({
      title: 'Modifier le statut de la mission',
      input: 'select',
      inputLabel: 'Choisissez un nouveau statut',
      inputOptions: this.getStatusOptions(),
      inputValue: mission.statut,
      showCancelButton: true,
      confirmButtonText: 'Enregistrer',
      cancelButtonText: 'Annuler',
      preConfirm: (newStatus) => {
        if (!newStatus) {
          Swal.showValidationMessage('Veuillez sélectionner un statut.');
        }
        return newStatus;
      }
    }).then((result) => {
      if (result.isConfirmed) {
        // Préparer les données à envoyer
        const updatedMission = { ...mission, statut: result.value };

        // Appeler le service pour mettre à jour la mission
        this.missionService.updateMission(mission.id, updatedMission).subscribe({
          next: () => {
            // Mettre à jour le statut localement
            mission.statut = result.value;
            Swal.fire('Statut modifié!', '', 'success');
          },
          error: (err) => {
            Swal.fire('Erreur!', 'La mise à jour a échoué.', 'error');
          }
        });
      }
    });
  }

  // Méthode pour générer les options de statut pour le menu déroulant
  getStatusOptions(): Record<string, string> {
    return this.statuts.reduce((options, status) => {
      options[status] = status;
      return options;
    }, {} as Record<string, string>);
  }


}
