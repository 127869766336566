import { Component, OnInit } from '@angular/core';
import { ChauffeurService } from 'src/app/Services/chauffeur.service';
import { MessageService } from 'src/app/Services/message.service';

@Component({
  selector: 'app-message-transporteur',
  templateUrl: './message-transporteur.component.html',
  styleUrls: ['./message-transporteur.component.css']
})
export class MessageTransporteurComponent implements OnInit {

  users: any[] = [];
  selectedUser: any;
  messages: any[] = [];
  newMessage: string = '';
  currentUser: any;

  constructor(
    private chauffeurService: ChauffeurService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.currentUser = this.getCurrentUser();
    this.fetchUsers();
  }

  fetchUsers(): void {
    const transporteurIdString = localStorage.getItem('idUser');
    const transporteurIdNumber = transporteurIdString ? parseInt(transporteurIdString, 10) : null;

    this.chauffeurService.getUsers().subscribe(data => {
      this.users = data.filter(user =>
        user.role === 'Chauffeur' && user.transporteurId === transporteurIdNumber
      );

      if (this.users.length > 0) {
        this.selectUser(this.users[0]); // Automatically select the first user
      }

      // Fetch unread messages count for each user
      this.users.forEach(user => {
        this.messageService.getUnreadMessagesCount(user.id).subscribe(count => {
          user.unreadMessagesCount = count;
          console.log("Unread messages count for user", user.id, ":", count);
        });
      });
    });
  }

  selectUser(user: any): void {
    this.selectedUser = user;
    console.log("Selected user ID: ", this.selectedUser.id);
    console.log("Current user ID: ", this.currentUser.id);

    // Mark messages as read for the selected user
    this.messageService.markMessagesAsRead(this.selectedUser.id).subscribe(() => {
      // Reload messages to reflect read status
      this.loadMessages(this.currentUser.id, this.selectedUser.id);
    });
  }

  sendMessage(): void {
    if (this.newMessage.trim()) {
      this.messageService.sendMessage(this.currentUser.id, this.selectedUser.id, this.newMessage)
        .subscribe(response => {
          this.messages.push(response);
          this.newMessage = ''; // Clear the message input after sending
        });
    }
  }

  loadMessages(user1Id: number, user2Id: number): void {
    this.messageService.getMessagesBetweenUsersForTransporteur(user1Id, user2Id)
      .subscribe(messages => {
        this.messages = messages;
        console.log("Loaded messages:", this.messages);
      });
  }

  getCurrentUser(): any {
    const transporteurIdString = localStorage.getItem('idUser');
    return { id: transporteurIdString, name: 'CurrentUser' }; // Example of returning the current user
  }
}
