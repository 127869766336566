import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChauffeurService {
  // private apiUrl = 'http://localhost:9067/api/chauffeur';
  private apiUrl = environment.APP_API_URL +"/api/chauffeur";

  constructor(private http: HttpClient) {}

  registerUser(user: any): Observable<HttpResponse<any>> {
    return this.http.post(this.apiUrl + "/add", user, { observe: 'response', responseType: 'text' });
  }

  getUserById(userId: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/${userId}`);
  }

  getUsers(): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl);
  }

  updateUser(id: string, user: any): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/${id}`, user);
  }

  deleteUser(id: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`);
  }

}
