<div class="row">

  <div class="col-md-6 mb-4">
    <div class="card shadow-sm border-0">
      <div class="card-header bg-gradient">
        <h5 class="card-title mb-0">Information Devis</h5>
        <span class="badge rounded-pill bg-primary text-white">Devis: {{ devis.id }}</span>
      </div>
      <div class="card-body">
        <div class="mb-3">
          <h6 class="text-muted mb-3">Information Client</h6>
          <hr>
          <p><strong>Nom Client:</strong> {{ devis.nomClient }}</p>
          <p><strong>Adresse Client:</strong> {{ devis.adresseClient }}</p>
          <p><strong>Email Client:</strong> {{ devis.emailClient }}</p>
          <p><strong>Téléphone Client:</strong> {{ devis.telephoneClient }}</p>
        </div>
        <div class="mb-3">
          <h6 class="text-muted mb-3">Information Devis</h6>
          <hr>
          <p><strong>Ville Départ:</strong> {{ devis.villeDepart }}</p>
          <p><strong>Ville Arrivée:</strong> {{ devis.villeArrivee }}</p>
          <p><strong>Date Livraison Souhaitée:</strong> {{ devis.dateLivraisonSouhaitee | date: 'shortDate' }}</p>
          <p><strong>Description Cargaison:</strong> {{ devis.descriptionCargaison }}</p>
          <p><strong>Type Cargaison:</strong> {{ devis.typeCargaison }}</p>
          <p><strong>Poids Cargaison:</strong> {{ devis.poidsCargaison }} kg</p>
          <p><strong>Volume Cargaison:</strong> {{ devis.volumeCargaison }} m³</p>
          <p><strong>Remarques:</strong> {{ devis.remarques }}</p>
          <p><strong>Statut:</strong> {{ devis.statut }}</p>
          <p><strong>Créé le:</strong> {{ devis.createdAt | date: 'short' }}</p>
        </div>
      </div>
    </div>
  </div>


  <div class="col-md-6 mb-4">

    <div class="card shadow-sm border-0 mb-4">
      <div class="card-header bg-gradient">
        <h5 class="card-title mb-0">Estimation Devis</h5>
      </div>
      <div class="card-body">
        <form [formGroup]="estimationDevisForm" (ngSubmit)="estimerDevis()">
          <div class="mb-3">
            <label for="distance" class="form-label">Distance (en km):</label>
            <input id="distance" formControlName="distance" type="number" min="0" step="0.01" class="form-control">
          </div>
          <div class="mb-3">
            <label for="prixKgKm" class="form-label">Prix de 1 kg/km:</label>
            <input id="prixKgKm" formControlName="prixKgKm" type="number" min="0" step="0.01" class="form-control">
          </div>
          <button type="submit" class="btn btn-primary">Estimer</button>
          
          <div *ngIf="estimation !== null" class="mt-3">
            <p>Estimation du devis:</p>
            <h4>{{ estimation }} DT</h4>
          </div>
        </form>
      </div>
    </div>

    <div class="card shadow-sm border-0">
      <div class="card-header bg-gradient">
        <h5 class="card-title mb-0">Répondre au Devis</h5>
      </div>
      <div class="card-body">
        <form [formGroup]="reponseDevisForm" (ngSubmit)="onSubmit()">
          <div class="mb-3">
            <label for="prix" class="form-label">Prix:</label>
            <input id="prix" formControlName="prix" type="number" min="0" step="0.01" class="form-control">
          </div>
          <button type="submit" class="btn btn-primary" [disabled]="reponseDevisForm.invalid">Enregistrer</button>
        </form>
      </div>
    </div>
  </div>
</div>
