<div class="wrapper">
  <h1>Missions des Chauffeurs</h1>

  <div class="mission-statistics">
    <h2>Statistiques des Missions</h2>
    <p>Total des missions: {{ totalMissions }}</p>
    <ul>
      <li>Prise en charge marchandise: {{ totalMissionsByStatus['Prise en charge marchandise'] }}</li>
      <li>Marchandise livrée: {{ totalMissionsByStatus['Marchandise livrée'] }}</li>
      <li>Revenu au parking: {{ totalMissionsByStatus['Revenu au parking'] }}</li>
      <li>Mission démarrée: {{ totalMissionsByStatus['Mission démarrée'] }}</li>
      <li>Mission créée: {{ totalMissionsByStatus['Mission créée'] }}</li>
      <li>Retourner au parking: {{ totalMissionsByStatus['Retourner au parking'] }}</li>
    </ul>
  </div>
  

  <div class="view-selector">
    <button (click)="changeView('day')" [ngClass]="{'active': view === 'day'}">Jour</button>
    <button (click)="changeView('week')" [ngClass]="{'active': view === 'week'}">Semaine</button>
    <button (click)="changeView('month')" [ngClass]="{'active': view === 'month'}">Mois</button>
  </div>

  <!-- Tableau de bord -->
  <div class="dashboard">
    <table>
      <thead>
        <tr>
          <th>Chauffeur</th>
          <th *ngFor="let day of days">{{ day }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let chauffeur of chauffeurs">
          <td class="chauffeur-name">{{ chauffeur.firstName }} {{ chauffeur.lastName }}

            <button (click)="showChauffeurDetails(chauffeur)"><i class="fa fa-address-card-o" aria-hidden="true"></i></button>
          </td>
         
          <td *ngFor="let day of days" class="mission-cell">
            <div *ngFor="let task of getTasks(chauffeur.id, day)" class="mission-info">
              <p>{{ task.description }}</p>
              <p>{{ formatDateTime(task.dateDebut) }} - {{ formatDateTime(task.dateFin) }}</p>
              <p>Statut: {{ task.statut }}</p>

              <div class="task-buttons">
                <button (click)="showVehiculeDetails(task.vehicule)">
                  <i class="fa fa-truck"></i>
                </button>
                <button [routerLink]="['/mission-history', task.id]">
                  <i class="fa fa-history"></i>
                </button>
                <button (click)="showMissionDetails(task)">
                  <i class="fa fa-file-text-o"></i>
                </button>
              </div>
              
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
