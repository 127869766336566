<div class="container">
  <div class="left-panel">
    <h1>Viridium Test App</h1>
    <p>
      Die Test-App ermöglicht es Ihnen, Verträge sowie deren zugehörige
      Informationen in verschiedenen Umgebungen zu suchen, indem Sie die
      erforderlichen Suchkriterien auswählen
    </p>
  </div>

  <div class="right-panel">
    <img src="assets/viridium-logo.png" alt="Viridium Logo" class="logo" />
    <h2>Login</h2>
    <form (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="email">Email Adresse</label>
        <input
          type="email"
          id="email"
          name="email"
          [(ngModel)]="email"
          placeholder="Email Adresse eingeben"
          required
        />
      </div>

      <div class="form-group">
        <label for="password">Passwort</label>
        <input
          type="password"
          id="password"
          name="password"
          [(ngModel)]="password"
          placeholder="Passwort"
          required
        />
      </div>

      <button type="submit" class="login-btn">Einloggen</button>
    </form>
  </div>
</div>
