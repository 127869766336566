import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {

  //private apiUrl = 'http://localhost:9067/api/user/resetPassword';

  private apiUrl = environment.APP_API_URL +"/api/resetPassword";

  constructor(private httpClient: HttpClient) { }

/*  resetPassword(email: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const body = { email: email };
    return this.httpClient.post(this.apiUrl, body, { headers: headers });
  }*/


  resetPassword(email: string): Observable<any> {
    const body = { email: email };
    return this.httpClient.post<any>(this.apiUrl, body, { responseType: 'text' as 'json' })
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unable to reset password. Please try again later.';
    if (error.error instanceof ErrorEvent) {
      // Erreur côté client
      console.error('An error occurred:', error.error.message);
    } else {
      // Erreur côté serveur
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      errorMessage = error.error;
    }
    Swal.fire('Error', errorMessage, 'error');
    return throwError(errorMessage);
  }
}
