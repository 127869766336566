import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/Services/user-service.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  // Définir la propriété User pour stocker les données de l'utilisateur
  User: any;

  constructor(private userService: UserService) { }

  ngOnInit(): void {
    const userId = localStorage.getItem('idUser');

    // Vérifier si l'id de l'utilisateur existe dans le localStorage
    if (userId) {
      this.userService.getUserById(userId).subscribe(data => {
        this.User = data;

        // Accéder au rôle de l'utilisateur une fois que les données sont chargées
        if (this.User && this.User.role) {
          console.log(this.User.role);
        } else {
          console.log('Role non disponible');
        }
      }, error => {
        console.error('Erreur lors de la récupération des données utilisateur', error);
      });
    } else {
      console.log('Aucun idUser trouvé dans le localStorage');
    }
  }
}
