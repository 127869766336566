import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MissionService {
  // private baseUrl = 'http://localhost:9067/api/missions';
  private baseUrl = environment.APP_API_URL +"/api/missions";
  
  constructor(private http: HttpClient) {}

  getMissions(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}`);
  }

  getMission(id: number): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/${id}`);
  }

  createMission(mission: any): Observable<any> {
    return this.http.post<any>(this.baseUrl, mission);
  }

  createWitheDevis(mission: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/withe-devis`, mission);
  }
  updateMission(id: number, mission: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/${id}`, mission);
  }

  deleteMission(id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }

  getFilteredMissions(): Observable<any[]> {
    const idTranspoteur = Number(localStorage.getItem('idUser'));
    return this.http.get<any[]>(this.baseUrl).pipe(
      map(missions => missions.filter(mission => mission.idTranspoteur === idTranspoteur))
    );
  }
}
