import { Component, OnInit } from '@angular/core';
import { ChauffeurService } from 'src/app/Services/chauffeur.service';
import { MessageService } from 'src/app/Services/message.service';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {

  users: any[] = [];
  selectedUser: any;
  messages: any[] = [];
  newMessage: string = '';
  currentUser: any;

  constructor(
    private chauffeurService: ChauffeurService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.fetchUsers();
    this.currentUser = this.getCurrentUser(); // Assurez-vous d'avoir une méthode pour obtenir l'utilisateur actuellement connecté
 
  }

  fetchUsers(): void {
    const transporteurIdString = localStorage.getItem('idUser');
    const transporteurIdNumber = transporteurIdString ? parseInt(transporteurIdString, 10) : null;

    this.chauffeurService.getUsers().subscribe(data => {
      this.users = data.filter(user =>
        user.role === 'Chauffeur' && user.transporteurId === transporteurIdNumber
      );

      if (this.users.length > 0) {
        this.selectUser(this.users[0]); // Sélectionner automatiquement le premier utilisateur
      }
    });
  }

  selectUser(user: any): void {
    this.selectedUser = user;
    console.log("selectedUser : ",  this.selectedUser.id)
    console.log("currentUser : ",  this.currentUser.id)
    this.loadMessages(this.currentUser.id, this.selectedUser.id); // Charger les messages entre l'utilisateur actuel et le sélectionné
  }

  sendMessage(): void {
    if (this.newMessage.trim()) {
      this.messageService.sendMessage(this.currentUser.id, this.selectedUser.id, this.newMessage)
        .subscribe(response => {
          this.messages.push(response);
          this.newMessage = '';
        });
    }
  }

  loadMessages(user1Id: number, user2Id: number): void {
    this.messageService.getMessagesBetweenUsersForTransporteur(user1Id, user2Id)
      .subscribe(messages => this.messages = messages);

      console.log("messages :", this.messages)
  }

  getCurrentUser(): any {
    const transporteurIdString = localStorage.getItem('idUser');
    return { id: transporteurIdString, name: 'CurrentUser' }; // Exemple de retour
  }
}