import { Component, OnInit } from '@angular/core';
import { MessageService } from 'src/app/Services/message.service';
import { UserService } from 'src/app/Services/user-service.service';

@Component({
  selector: 'app-message-chauffeur',
  templateUrl: './message-chauffeur.component.html',
  styleUrls: ['./message-chauffeur.component.css']
})
export class MessageChauffeurComponent implements OnInit {

  messages: any[] = [];
  newMessage: string = '';
  currentUser: any;
  otherUserId: number | null = null;

  constructor(
    private messageService: MessageService, 
    private userService: UserService
  ) {}

  ngOnInit(): void {
    const userIdString = localStorage.getItem('idUser');
    const userId = userIdString ? parseInt(userIdString, 10) : null;

    if (userId) {
      this.userService.getUserById(userIdString || '').subscribe(data => {
        this.currentUser = data;
        this.otherUserId = this.currentUser.transporteurId; // Assurez-vous que transporteurId est défini
        console.log("Current user:", this.currentUser);
        this.loadMessages(); // Charger les messages après avoir récupéré les données de l'utilisateur
      }, error => {
        console.error('Erreur lors de la récupération des données utilisateur', error);
      });
    } else {
      console.log('Aucun idUser trouvé dans le localStorage');
    }
  }

  loadMessages(): void {
    if (this.currentUser && this.otherUserId) {
      const userId = this.currentUser.id;
      this.messageService.getMessagesBetweenUsersForChauffeur(userId, this.otherUserId)
        .subscribe(messages => {
          this.messages = messages;
          console.log("Messages reçus :", this.messages);
        }, error => {
          console.error('Erreur lors du chargement des messages', error);
        });
    } else {
      console.error('Utilisateur ou transporteurId non défini');
    }
  }

  sendMessage(): void {
    if (this.newMessage.trim() && this.currentUser && this.otherUserId) {
      this.messageService.sendMessage(this.currentUser.id, this.otherUserId, this.newMessage)
        .subscribe(response => {
          this.messages.push(response);
          this.newMessage = '';
        }, error => {
          console.error('Erreur lors de l\'envoi du message', error);
        });
    }
  }
}
