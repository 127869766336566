<div class="container">
  <h1 style="margin-top:2rem; margin-bottom: 2rem; text-align: center;font-family: 'Open Sans',sans-serif; font-weight: 900; color: #002e51;">Demande de devis en ligne </h1>
 
  <p style="color: gray;  text-align: center;">
    Notez que si vous sélectionnez une ville de départ, la liste des transporteurs sera automatiquement filtrée en fonction de cette ville.
  </p>
  <hr style="width: 40rem;  margin: 25px auto;">
  <form [formGroup]="devisForm" (ngSubmit)="onSubmit()">
    <!-- Ville Départ et Ville Arrivée -->
    <div class="row mb-3">
      <div class="col-md-6">
        <label for="villeDepart" class="form-label">Ville Départ :</label>
        <select id="villeDepart" formControlName="villeDepart" class="form-select" (change)="onVilleDepartChange($event)">
          <option value="" disabled selected>Code postal, Ville</option>
          <option *ngFor="let ville of villesList" [value]="ville.codePostal + ', ' + ville.ville">
            {{ ville.codePostal }}, {{ ville.ville }} 
          </option>
        </select>
        <input type="radio" id="adressePro" name="adresse" value="professionnelle">
        <label for="adressePro" class="form-check-label">j'expédie depuis une adresse professionnelle.</label>
      </div>
      
      <div class="col-md-6">
        <label for="villeArrivee" class="form-label">Ville Arrivée :</label>
        <select id="villeArrivee" formControlName="villeArrivee" class="form-select">
          <option value="" disabled selected>Code postal, Ville</option>
          <option *ngFor="let ville of villesList" [value]="ville.codePostal + ', ' + ville.ville">
            {{ ville.codePostal }}, {{ ville.ville }} 
          </option>
        </select>
        <input type="radio" id="adresseProLivraison" name="adresse" value="professionnelleLivraison">
        <label for="adresseProLivraison" class="form-check-label">je livre à une adresse professionnelle.</label>
      </div>
    </div>
    
  
    <!-- Envoi Section -->
    <h6>Envoi</h6>
    <hr>
      <div formArrayName="ligneEnvoi">
        <button type="button" class="btn-add" (click)="addLigneEnvoi()">
          <i class="fa fa-plus-circle" aria-hidden="true"></i> Ajouter Marchandise
        </button>
        <hr>

<!-- Table to display the list -->
<table class="table">
  <thead>
    <tr>
      <th>Id</th>
      <th>Description Marchandise</th>
      <th>Type d'Emballage</th>
      <th>Quantité</th>
      <th>Poids (kg)</th>
      <th>Longueur (cm)</th>
      <th>Largeur (cm)</th>
      <th>Hauteur (cm)</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let ligne of ligneEnvoi.controls; let i = index" [formGroupName]="i">
      <td>{{ i+1 }}</td>
      <td>{{ ligne.value.descriptionMarchandise }}</td>
      <td>{{ ligne.value.typeEmbalage }}</td>
      <td>{{ ligne.value.quantite }}</td>
      <td>{{ ligne.value.poids }}</td>
      <td>{{ ligne.value.longeur }}</td>
      <td>{{ ligne.value.largeur }}</td>
      <td>{{ ligne.value.hoteur }}</td>
      <td>
        <button (click)="removeLigneEnvoi(i)" class="btn-remove">
          <i class="fa fa-trash" aria-hidden="true"></i>
        </button>
      </td>
    </tr>
  </tbody>
</table>

      </div>
    
    <!-- Client Information -->
    <h6>Client</h6>
    <hr>
  
    <div class="row mb-3">
      <div class="col-md-6">
        <label for="nomClient" class="form-label">Nom du Client:</label>
        <input id="nomClient" formControlName="nomClient" type="text" class="form-control">
      </div>
      <div class="col-md-6">
        <label for="telephoneClient" class="form-label">Téléphone du Client:</label>
        <input id="telephoneClient" formControlName="telephoneClient" type="text" class="form-control">
      </div>
    </div>
  
    <div class="row mb-3">
      <div class="col-md-6">
        <label for="emailClient" class="form-label">Email du Client:</label>
        <input id="emailClient" formControlName="emailClient" type="email" class="form-control">
      </div>
      <div class="col-md-6">
        <label for="dateLivraisonSouhaitee" class="form-label">Date de livraison Souhaitée:</label>
        <input id="dateLivraisonSouhaitee" formControlName="dateLivraisonSouhaitee" type="date" class="form-control">
      </div>
    </div>
        <!-- Transporteurs -->
        <div formArrayName="idsTransporteur" class="mb-3">
          <label for="transporteurs" class="form-label">Transporteurs:</label>
          <div *ngIf="message" class="alert alert-warning" role="alert">
            {{ message }}
          </div>
          <div *ngFor="let transporteur of filteredTransporteurs; let i = index" class="form-check">
            <input 
              type="checkbox" 
              [formControlName]="i" 
              [value]="transporteur.id"
              class="form-check-input" 
              id="transporteur{{i}}">
            <label class="form-check-label" for="transporteur{{i}}">
              {{ transporteur.firstName }} {{ transporteur.lastName }}
            </label>
            <i class="fa fa-address-card-o ms-2" aria-hidden="true" (click)="viewTransporteur(transporteur)"></i>
          </div>
        </div>
          <!-- Pagination controls -->
    <div class="pagination">
      <button (click)="goToPage(currentPage - 1)" [disabled]="currentPage === 1">Précédent</button>
      <span>Page {{ currentPage }} sur {{ totalPages }}</span>
      <button (click)="goToPage(currentPage + 1)" [disabled]="currentPage === totalPages">Suivant</button>
    </div>
    <button type="submit" class="btn btn-primary">Envoyer</button>
  </form>
  
</div>