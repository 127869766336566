<div class="chat-container">
  <div class="chat-sidebar">
      <h2>Conversations</h2>
      <ul class="user-list">
        <li *ngFor="let user of users" 
        class="user-item"
        [class.active]="user === selectedUser"
        (click)="selectUser(user)">
      
      <!-- Affiche l'image par défaut si user.image est null ou undefined, sinon affiche l'image de l'utilisateur -->
      <img [src]="user?.image?.picByte ? 'data:image/jpeg;base64,' + user?.image.picByte : '/assets/profile-avatar.png'" 
           alt="Profile Picture" class="user-avatar"> 
    
      {{ user.firstName || user.userName }} <!-- Affiche le nom ou le nom d'utilisateur -->
    </li>
    
      </ul>
  </div>
  
  <div class="chat-main" *ngIf="selectedUser">
      <div class="chat-header">
          <!-- Afficher l'image du profil de l'utilisateur et son nom dans l'en-tête -->
          <div class="user-info">
              <img *ngIf="selectedUser?.image" 
                   [src]="'data:image/jpeg;base64,' + selectedUser?.image.picByte" 
                   alt="Profile Picture" 
                   class="profile-picture-header">
              <h2>Discussion avec {{ selectedUser.firstName }}</h2>
          </div>
      </div>
      
      <div class="chat-messages">
          <div *ngFor="let message of messages" 
               [ngClass]="{'sent': message.sender.id === currentUser.id, 'received': message.sender.id !== currentUser.id}" 
               class="message">
              <p><strong>{{ message.sender.firstName }}:</strong> {{ message.content }}</p>
              <span class="message-time">{{ message.time }}</span>
          </div>
      </div>
      
      <div class="chat-input">
          <input type="text" [(ngModel)]="newMessage" placeholder="Tapez un message...">
          <button class="send-button" (click)="sendMessage()">Envoyer</button>
      </div>
  </div>
</div>

