import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class VehiculeService {
 // private apiUrl = 'http://localhost:9067/api/vehicule';

  private apiUrl = environment.APP_API_URL +"/api/vehicule";

  constructor(private http: HttpClient) { }

  getVehicules(): Observable<any> {
    return this.http.get<any>(this.apiUrl);
  }

  getVehiculeById(id: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/${id}`);
  }

  /*addVehicule(vehicule: any): Observable<any> {
    return this.http.post<any>(this.apiUrl, vehicule);
  }*/
    addVehicule(vehicule: any, image: File): Observable<any> {
      const formData: FormData = new FormData();
      formData.append('vehicule', new Blob([JSON.stringify(vehicule)], { type: 'application/json' }));
      formData.append('image', image);
      return this.http.post<any>(`${this.apiUrl}/add`, formData);
    }

 /* updateVehicule(id: number, vehicule: any): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/${id}`, vehicule);
  }
*/

updateVehicule(id: number, updatedVehicule: any): Observable<any> {
  const formData = new FormData();
  formData.append('vehicule', JSON.stringify(updatedVehicule));
  if (updatedVehicule.image) {
    formData.append('image', updatedVehicule.image);
  }
  
  return this.http.put(`${this.apiUrl}/${id}`, formData);
}

  deleteVehicule(id: number): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/${id}`);
  }
}
