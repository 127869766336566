import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DevisService } from 'src/app/Services/devis.service';
import { MissionService } from 'src/app/Services/mission.service';
import { UserService } from 'src/app/Services/user-service.service';
import { VehiculeService } from 'src/app/Services/vehicule.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-mission',
  templateUrl: './add-mission.component.html',
  styleUrls: ['./add-mission.component.css']
})
export class AddMissionComponent implements OnInit {

  missionForm!: FormGroup;
  vehicules: any[] = [];
  chauffeurs: any[] = [];
  idDevis: number = 0;
  userId: string | null = null;
  devis: any | null = null;

  constructor(
    private fb: FormBuilder,
    private missionService: MissionService,
    private vehiculeService: VehiculeService,
    private userService: UserService,
    private route: ActivatedRoute,
    private devisService: DevisService
  ) {}

  ngOnInit(): void {
    // Initialiser le formulaire sans valeur par défaut pour 'dateDebut' ici
    this.missionForm = this.fb.group({
      dateDebut: ['', Validators.required], // Valeur sera mise à jour après récupération du devis
      dateFin: ['', Validators.required],
      description: ['', Validators.required],
      vehiculeId: ['', Validators.required],
      chauffeurId: ['', Validators.required]
    });

    this.route.params.subscribe(params => {
      this.idDevis = params['id'];
    });

    this.userId = this.isUserAuthenticated();

    // Récupération des véhicules
    this.vehiculeService.getVehicules().subscribe(
      (data: any[]) => {
        if (this.userId !== null) {
          const userIdNumber = parseInt(this.userId, 10);
          this.vehicules = data.filter(item => item.transporteurId === userIdNumber);
        } else {
          this.vehicules = [];
        }
      },
      error => {
        console.error('Erreur lors de la récupération des véhicules :', error);
      }
    );

    // Récupération des chauffeurs
    this.userService.getChauffeurs().subscribe(
      (data: any[]) => {
        if (this.userId !== null) {
          const userIdNumber = parseInt(this.userId, 10);
          this.chauffeurs = data.filter(item => item.transporteurId === userIdNumber);
        } else {
          this.chauffeurs = [];
        }
      },
      error => {
        console.error('Erreur lors de la récupération des chauffeurs :', error);
      }
    );

    this.getDevis(); // Récupérer les données du devis
  }

  // Méthode pour récupérer un devis par ID
  getDevis() {
    this.devisService.getDevisById(this.idDevis).subscribe(
      (data: any) => {
        this.devis = data;
        console.log("Le devis récupéré est : ", this.devis);

        // Mettre à jour la valeur de 'dateDebut' avec 'dateLivraisonSouhaitee'
        if (this.devis && this.devis.dateLivraisonSouhaitee) {
          const dateLivraisonSouhaitee = new Date(this.devis.dateLivraisonSouhaitee)
            .toISOString()
            .slice(0, 16); // Format pour 'datetime-local'

          this.missionForm.patchValue({
            dateDebut: dateLivraisonSouhaitee
          });
        }
      },
      (error) => {
        console.error("Erreur lors de la récupération du devis :", error);
        this.devis = null;
      }
    );
  }

  // Soumettre le formulaire
  onSubmit(): void {
    if (this.missionForm.valid) {
      const formValue = {
        ...this.missionForm.value,
        devisId: this.idDevis,
        transporteurId: window.localStorage.getItem('idUser') // Assigner l'utilisateur courant
      };

      console.log("Valeurs du formulaire", formValue);

      // Appeler le service pour créer la mission
      this.missionService.createMission(formValue).subscribe(
        response => {
          Swal.fire({
            title: 'Succès!',
            text: 'Mission créée avec succès.',
            icon: 'success',
            confirmButtonText: 'OK'
          });
        },
        error => {
          Swal.fire({
            title: 'Erreur!',
            text: 'Erreur lors de la création de la mission.',
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      );
    }
  }

  // Vérifier si l'utilisateur est authentifié
  isUserAuthenticated(): string | null {
    return localStorage.getItem('idUser');
  }
}
