import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ChauffeurService } from 'src/app/Services/chauffeur.service';
import { ImageUploadService } from 'src/app/Services/image-upload.service';
import { HttpErrorResponse } from '@angular/common/http';
import { VilleService } from 'src/app/Services/ville.service';

@Component({
  selector: 'app-chouffeur-form',
  templateUrl: './chouffeur-form.component.html',
  styleUrls: ['./chouffeur-form.component.css']
})
export class ChouffeurFormComponent implements OnInit {
  errorMessage: string | undefined;
  registerForm: FormGroup;
  confirmPasswordError: string = '';

  selectedFile!: File;
  imgURL: any;
  retrievedImage: any;
  base64Data: any;
  retrieveResonse: any;
  message!: string;
  imageName: any;
  villesList: any[] = [];
 

  step1=true;
  step2=false;
  step3=false;


 // selectedFiles: { [key: string]: File } = {};
 selectedFiles: File[] = [];

  constructor( private villeService: VilleService, private fb: FormBuilder, private chauffeurService: ChauffeurService,private router: Router,private imageUploadService: ImageUploadService) {
    this.registerForm = this.fb.group({
      userName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneNumber: ['', [Validators.required, Validators.minLength(8)]],
      cin: ['', [Validators.required, Validators.minLength(8)]],
      address: ['', Validators.required],
      city: ['', Validators.required],
      dateOfBirth: ['', Validators.required],
      experienceYears: ['', Validators.required],
      ratings: ['', Validators.required],
      drivingLicenseCategory: ['',Validators.required],
      driverPermisId: ['',Validators.required],
      emergencyContactPhone: ['',Validators.required],
      maritalStatus: ['',Validators.required],
      transporteurId: [null],  
    }, { validators: this.passwordMatchValidator });
  }

  ngOnInit(): void {
    this.villeService.getVilles().subscribe(data => {
      this.villesList = data;
    });
  }

  get userName() {
    return this.registerForm.get('userName');
  }

  get email() {
    return this.registerForm.get('email');
  }

  get password() {
    return this.registerForm.get('password');
  }

  get confirmPassword() {
    return this.registerForm.get('confirmPassword');
  }

  
  get firstName() {
    return this.registerForm.get('firstName');
  }
  
  get lastName() {
    return this.registerForm.get('lastName');
  }
  
  get phoneNumber() {
    return this.registerForm.get('phoneNumber');
  }

  get cin() {
    return this.registerForm.get('cin');
  }
  get address() {
    return this.registerForm.get('address');
  }
  
  get city() {
    return this.registerForm.get('city');
  }
  
  get postalCode() {
    return this.registerForm.get('postalCode');
  }
  
  get dateOfBirth() {
    return this.registerForm.get('dateOfBirth');
  }
  
  get vehicleType() {
    return this.registerForm.get('vehicleType');
  }

  get experienceYears() {
    return this.registerForm.get('experienceYears');
  }

  get ratings() {
    return this.registerForm.get('ratings');
  }



  get monthlyProfessionalRatings() {
    return this.registerForm.get('monthlyProfessionalRatings');
  }

  get drivingLicenseCategory() {
    return this.registerForm.get('drivingLicenseCategory');
  }
  get driverPermisId() {
    return this.registerForm.get('driverPermisId');
  }
  get emergencyContactPhone() {
    return this.registerForm.get('emergencyContactPhone');
  }
  get maritalStatus() {
    return this.registerForm.get('maritalStatus');
  }


  passwordMatchValidator(formGroup: FormGroup) {
    const password = formGroup.get('password');
    const confirmPassword = formGroup.get('confirmPassword');

    if (password && confirmPassword && confirmPassword.value !== password.value) {
      confirmPassword.setErrors({ mismatch: true });
    } else if (confirmPassword) {
      confirmPassword.setErrors(null);
    }
  }

  private showFieldErrors() {
    const controls = this.registerForm.controls;
    let errorMessages: string[] = [];
    
    // Check for empty required fields
    Object.keys(controls).forEach(key => {
      const control = controls[key];
      if (control.invalid && (control.dirty || control.touched)) {
        let errorMessage = '';
        if (control.errors?.['required']) {
          errorMessage = `${this.getFieldName(key)} is required.`;
        } else if (control.errors?.['minlength']) {
          errorMessage = `${this.getFieldName(key)} should be at least ${control.errors['minlength'].requiredLength} characters long.`;
        } else if (control.errors?.['email']) {
          errorMessage = `Invalid email format.`;
        } else if (control.errors?.['mismatch']) {
          errorMessage = 'Passwords do not match.';
        }
        if (errorMessage) {
          errorMessages.push(errorMessage);
        }
      }
    });
  
    // Check if there are any errors to display
    if (errorMessages.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Errors',
        html: `<ul>${errorMessages.map(msg => `<li>${msg}</li>`).join('')}</ul>`
      });
    }
  }
  
  // Helper function to get a friendly field name
  private getFieldName(key: string): string {
    const fieldNames: { [key: string]: string } = {
      userName: 'Username',
      email: 'Email',
      password: 'Password',
      confirmPassword: 'Confirm Password',
      firstName: 'First Name',
      lastName: 'Last Name',
      phoneNumber: 'Phone Number',
      cin: 'CIN',
      address: 'Address',
      city: 'City',
      postalCode: 'Postal Code',
      dateOfBirth: 'Date of Birth',
      vehicleType: 'Vehicle Type',
      experienceYears: 'Experience Years',
      ratings: 'Ratings',
      monthlyProfessionalRatings: 'Monthly Professional Ratings',
      drivingLicenseCategory: 'Driving License Category',
      driverPermisId: 'Driver Permit ID',
      emergencyContactPhone: 'Emergency Contact Phone',
      maritalStatus: 'Marital Status'
    };
    return fieldNames[key] || key;
  }

  
  onSubmit() {
    if (this.registerForm.invalid) {
      this.showFieldErrors();
      return;
    }
  
    const userIdString = localStorage.getItem('idUser');
    const transporteurId = userIdString ? userIdString : null;
    this.registerForm.patchValue({ transporteurId });
  
    this.chauffeurService.registerUser(this.registerForm.value).subscribe(
      response => {
        console.log('response :', response.body);
        const responseBody = response.body?.trim(); // Assurez-vous de nettoyer la réponse de tout espace blanc éventuel
    
        if (responseBody === 'Email already exists') {
          this.errorMessage = 'Email already exists.';
          Swal.fire({
            icon: 'error',
            title: 'Email already exists.',
            text: 'Email already exists.'
          });
        } else if (responseBody === 'User name already exists') {
          this.errorMessage = 'User name already exists.';
          Swal.fire({
            icon: 'error',
            title: 'User name already exists.',
            text: 'User name already exists.'
          });
        } else {
          try {
            const parsedResponse = JSON.parse(responseBody);
            if (parsedResponse.message === 'User registered successfully' && parsedResponse.userId) {
              // Enregistrer userId dans le localStorage
              localStorage.setItem('userId', parsedResponse.userId);
      
              Swal.fire({
                icon: 'success',
                title: 'Registration Successful',
                text: 'User registered successfully. You can now login.',
                showConfirmButton: true
              }).then((result) => {
                if (result.isConfirmed) {
                  this.step1 = false;
                  this.step2 = true;
                }
              });
            } else {
              console.error('Unexpected response from server:', responseBody);
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Unexpected response from server. Please try again later.'
              });
            }
          } catch (error) {
            console.error('Error parsing response:', error);
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Error parsing response. Please try again later.'
            });
          }
        }
      },
      error => {
        console.error('Error registering user', error);
        this.errorMessage = 'Error registering user. Please try again.';
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: this.errorMessage
        });
      }
    );
  }
  

  public onFileChanged(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      this.selectedFile = inputElement.files[0];
    }
  }

  onUpload() {
    console.log(this.selectedFile);

    const userIdString = localStorage.getItem('userId');
    if (!userIdString) {
      console.error('No user ID found in localStorage');
      return;
    }
  
    const userId = +userIdString; // Convert string to number
  

    const uploadImageData = new FormData();
    uploadImageData.append('imageFile', this.selectedFile, this.selectedFile.name);
    this.step2=false;
    this.step3=true;
    this.imageUploadService.uploadImage(uploadImageData, userId)
      .subscribe(response => {
        this.step2=false;
        this.step3=true;
        if (response.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Registration Successful',
            text: 'User registered successfully. You can now login.',
            showConfirmButton: true
          }).then((result) => {
            if (result.isConfirmed) {
          
            }
          });
        } else {
          console.log('Image not uploaded successfully');
        }
      });
  }

  onUpload_2() {
    console.log(this.selectedFile);

    const userIdString = localStorage.getItem('userId');
    if (!userIdString) {
      console.error('No user ID found in localStorage');
      return;
    }
  
    const userId = +userIdString; // Convert string to number
  

    const uploadImageData = new FormData();
    uploadImageData.append('imageFile', this.selectedFile, this.selectedFile.name);
    this.step2=false;
    this.step3=true;
    this.imageUploadService.uploadImage(uploadImageData, userId)
      .subscribe(response => {
        this.step2=false;
        this.step3=true;
        if (response.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Registration Successful',
            text: 'User registered successfully. You can now login.',
            showConfirmButton: true
          }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigateByUrl('/list-chauffeur');
            }
          });
        } else {
          console.log('Image not uploaded successfully');
        }
      });
  }

    
}
