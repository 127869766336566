<div class="reset-password-container">
    <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="email">Email</label>
        <input id="email" formControlName="email" type="email" class="form-control" placeholder="Enter your email" />
        <div *ngIf="resetPasswordForm.get('email')?.invalid && (resetPasswordForm.get('email')?.touched || resetPasswordForm.get('email')?.dirty)" class="text-danger">
          Valid email is required
        </div>
      </div>
      <button type="submit" class="btn btn-primary" [disabled]="resetPasswordForm.invalid">Reset Password</button>
    </form>
    <div *ngIf="message" class="alert alert-info mt-3">{{ message }}</div>
  </div>
  
