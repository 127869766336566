import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {
  mots: string[] = ['camion', 'avion', 'Bateau'];
  motActuel: string = '';
  index: number = 0;
  constructor() { }

  ngOnInit(): void {
    this.afficherMot();
  }
  afficherMot() {
    this.motActuel = this.mots[this.index];
    this.index = (this.index + 1) % this.mots.length;
    setTimeout(() => this.afficherMot(), 3000);
  }
}
