<div *ngIf="errorMessage" class="error-message">
  {{ errorMessage }}
</div>
<div *ngIf="devis" class="devis-details">
  <h2>Détails de la demande Devis</h2>
  <div class="info-section">
    <h3>Informations sur le Devis</h3>
    <div class="info-block">
      <strong>Infos Client:</strong>
      <hr>
      <p><span>Nom Client:</span> {{ devis.nomClient }}</p>
      <p><span>Téléphone Client:</span> {{ devis.telephoneClient }}</p>
      <p><span>Email Client:</span> {{ devis.emailClient }}</p>
    </div>
    <div class="info-block">
      <strong>Infos Devis:</strong>
      <hr>
      <p><span>Ville de Départ:</span> {{ devis.villeDepart }}</p>
      <p><span>Ville d'Arrivée:</span> {{ devis.villeArrivee }}</p>
      <p><span>Date de Livraison Souhaitée:</span> {{ devis.dateLivraisonSouhaitee | date }}</p>
      <p><span>Code Réponse Devis:</span> {{ devis.codeReponseDevis }}</p>
      <p><span>Statut:</span> {{ devis.statut }}</p>
      <p><span>Date Demande:</span> {{ devis.dateDemande | date }}</p>
    </div>

    <div class="info-section">
      <h3>Lignes d'envoi</h3>
      <div *ngFor="let ligne of devis.ligneEnvoi; let i = index" class="ligne-envoi">
        <div class="info-block">
          <strong>Marchandise {{ i + 1 }}:</strong>
          <hr>
          <p><span>Description Marchandise:</span> {{ ligne.descriptionMarchandise }}</p>
          <p><span>Type d'Emballage:</span> {{ ligne.typeEmbalage }}</p>
          <p><span>Quantité:</span> {{ ligne.quantite }}</p>
          <p><span>Poids:</span> {{ ligne.poids }} kg</p>
          <p><span>Longueur:</span> {{ ligne.longeur }} cm</p>
          <p><span>Largeur:</span> {{ ligne.largeur }} cm</p>
          <p><span>Hauteur:</span> {{ ligne.hoteur }} cm</p>
          <p><span>Produit Dangereux:</span> {{ ligne.produitDangereux }}</p>
        </div>
      </div>
    </div>
  </div>
</div>

