<div class="login-container">
  <div class="login-wrapper">
    <div class="login-form">

      <h1 style="margin-top:2rem; margin-bottom: 2rem; text-align: center;font-family: 'Open Sans',sans-serif; font-weight: 900; color: #002e51;">Créer un compte</h1>
      <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <div class="form-group mb-3">
            <select id="role" formControlName="role" class="form-select">
              <option value="" disabled selected>Choisissez votre rôle (Transporteur ou Client)</option>
              <option value="Transporteur">Transporteur</option>
              <option value="Client">Client</option>
            </select>
            <div *ngIf="role?.invalid && (role?.touched || role?.dirty)" class="text-danger mt-2">
              Le rôle est requis
            </div>
          </div>
          <div class="input-container">
            <input id="userName" class="form-control input--style-5" formControlName="userName" placeholder="Enter your username" />
          </div>
          <div *ngIf="userName?.invalid && (userName?.touched || userName?.dirty)" class="text-danger mt-2">
            User Name is required
          </div>
        </div>
        <div class="form-group">
          <input id="firstName" class="form-control input--style-5" formControlName="firstName" placeholder="Entrez votre prénom" />
          <div *ngIf="firstName?.invalid && (firstName?.touched || firstName?.dirty)" class="text-danger mt-2">
            Le prénom est requis
          </div>
        </div>

        <div class="form-group">
          <input id="lastName" class="form-control input--style-5" formControlName="lastName" placeholder="Entrez votre nom" />
          <div *ngIf="lastName?.invalid && (lastName?.touched || lastName?.dirty)" class="text-danger mt-2">
            Le nom est requis
          </div>
        </div>

        <div class="form-group">
          
            <input id="email" type="email" class="form-control input--style-5" formControlName="email" placeholder="Enter your email" />
        
          <div *ngIf="email?.invalid && (email?.touched || email?.dirty)" class="text-danger mt-2">
            Valid email is required
          </div>
        </div>

        <div class="form-group mb-3">
          <input id="phoneNumber" class="form-control input--style-5" formControlName="phoneNumber" placeholder="Entrez votre numéro de téléphone" />
          <div *ngIf="phoneNumber?.invalid && (phoneNumber?.touched || phoneNumber?.dirty)" class="text-danger mt-2">
            Le numéro de téléphone est requis
          </div>
        </div>
        <div class="form-group mb-3">
          <input id="address" class="form-control input--style-5" formControlName="address" placeholder="Entrez votre adresse" />
          <div *ngIf="address?.invalid && (address?.touched || address?.dirty)" class="text-danger mt-2">
            L'adresse est requise
          </div>
        </div>
        <div class="form-group mb-3">
          <select id="city" formControlName="city" class="form-select">
            <option value="" disabled selected>Code postal, Ville</option>
            <option *ngFor="let ville of villesList" [value]="ville.codePostal + ', ' + ville.ville">
                {{ ville.codePostal }}, {{ ville.ville }} 
            </option>
        </select>
        </div>
      
        <div class="form-group">
         
            <input id="password" type="password" class="form-control input--style-5" formControlName="password" placeholder="Enter your password" />
     
          <div *ngIf="password?.invalid && (password?.touched || password?.dirty)" class="text-danger mt-2">
            Password is required (min 8 characters)
          </div>
        </div>

        <div class="form-group">
     
            <input id="confirmPassword" type="password" class="form-control input--style-5" formControlName="confirmPassword" placeholder="Confirm your password" />
 
          <div *ngIf="confirmPassword?.invalid && (confirmPassword?.touched || confirmPassword?.dirty)" class="text-danger mt-2">
            {{ confirmPasswordError }}
          </div>
        </div>

        <div class="button-container">
          <button type="submit" [disabled]="registerForm.invalid">Register</button>
        </div>
      </form>
    </div>
    <div class="login-image">
      <img src="../assets/register-img.jpg" alt="Login Image">
    </div>
  </div>
</div>
