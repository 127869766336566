<div class="user-table-container" *ngIf="users && users.length > 0">
    <button class="btn-export-excel" (click)="exportToExcel()">Télécharger Excel</button>
    <table class="styled-table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Nom</th>
          <th>Prénom</th>
          <th>Nom d'utilisateur</th>
          <th>Email</th>
          <th>Téléphone</th>
          <th>Rôle</th>
      
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of users">
            <td>{{ user.id  || '-'  }}</td>
            <td>{{ user.firstName || '-' }} </td>
            <td>{{ user.lastName || '-' }}</td>
       
          <td>{{ user.userName  || '-' }}</td>
          <td>{{ user.email  || '-' }}</td>
          <td>{{ user.phoneNumber || '-' }}</td>
          <td>{{ user.role  || '-'  }}</td>
      
        </tr>
      </tbody>
    </table>
  </div>
  
  <div class="no-data-message" *ngIf="!users || users.length === 0">
    <p>Aucun utilisateur trouvé.</p>
  </div>
  