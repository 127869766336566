import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfirmDevisService {
  // private apiUrl = 'http://localhost:9067/api/confirmDevis';
  private apiUrl = environment.APP_API_URL +"/api/confirmDevis";
  
  private httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }

  getConfirmDevis(): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl);
  }

  createConfirmDevis(confirmDevis: any): Observable<any> {
    return this.http.post<any>(this.apiUrl, confirmDevis, this.httpOptions);
  }

  updateConfirmDevis(confirmDevis: any): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/${confirmDevis.id}`, confirmDevis, this.httpOptions);
  }

}
