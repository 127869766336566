<div class="container mt-4">
  <h2 class="section-title text-center mb-4">Liste des chauffeurs</h2>

  <div class="row align-items-center mb-3">
    <div class="col-md-6">
      <input type="text" [(ngModel)]="searchText" class="form-control search-input" placeholder="Rechercher..." />
    </div>
    <div class="col-md-6 text-md-end">
      <a class="btn btn-outline-primary" [routerLink]="['/form-chauffeur']">Ajouter un chauffeur</a>
    </div>
  </div>

  <div class="text-end mb-3">
    Total d'éléments : <span class="badge badge-pill badge-primary">{{ users.length }}</span> 
  </div>

  <div class="card-deck">
    <div class="card mb-4 mt-3 shadow-sm" *ngFor="let user of users | paginate: { itemsPerPage: 4, currentPage: page, totalItems: totalLength } | filter:searchText">
      <div class="card-body">
        <h5 class="card-title">{{ user.firstName }} {{ user.lastName }}</h5>
        <hr>
        <p class="card-text"><strong>Email:</strong> {{ user.email }}</p>
        <p class="card-text"><strong>Numéro de téléphone:</strong> {{ user.phoneNumber }}</p>
        <p class="card-text small-text text-muted">Créé le: {{ user.created_at | date: 'short' }}</p>
        <p class="card-text small-text text-muted">Mis à jour le: {{ user.updated_at | date: 'short' }}</p>
      
      </div>
      <div class="card-footer bg-light-gray d-flex justify-content-between align-items-center rounded-bottom">
        <div class="btn-group">
          <a class="btn btn-sm btn-outline-primary" [routerLink]="['/details-chauffeur', user.id]">Détails</a>
          <a class="btn btn-sm btn-outline-primary" [routerLink]="['/update-chauffeur', user.id]">Éditer</a>
        </div>
        <button class="btn btn-sm btn-outline-danger" (click)="deleteUser(user.id)">Supprimer</button>
      </div>
    </div>
  </div>
  
  <div class="d-flex justify-content-center mt-3">
    <pagination-controls (pageChange)="page = $event"></pagination-controls>
  </div>
</div>