import { Component, OnInit } from '@angular/core';
import { ChauffeurService } from 'src/app/Services/chauffeur.service';
import { MissionService } from 'src/app/Services/mission.service';
import { UserService } from 'src/app/Services/user-service.service';
import { VehiculeService } from 'src/app/Services/vehicule.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.css']
})
export class ProfilComponent implements OnInit {
  user: any;
  vehicules : any ;
  users: any ;
  missions : any;
  constructor(private missionService: MissionService, private userService: UserService, 
    private vehiculeService: VehiculeService, 
    private chauffeurService: ChauffeurService) { }

  ngOnInit(): void {
    this.loadUserData();
    this.getVehicules();

    this.fetchUsers();
    this.loadMissions();
  }
  loadMissions(): void {
    // Récupérer l'ID du transporteur depuis le localStorage
    const idTranspoteur = Number(localStorage.getItem('idUser'));
  
    // Récupérer les missions depuis le service et les filtrer par idTranspoteur
    this.missionService.getMissions().subscribe(missions => {
      this.missions = missions.filter(mission => mission.idTranspoteur === idTranspoteur);
    });
  }
  

    fetchUsers(): void {
      // Récupérer transporteurId depuis localStorage
      const transporteurIdString = localStorage.getItem('idUser');
    
      // Convertir transporteurId en nombre, si transporteurIdString n'est pas null
      const transporteurIdNumber = transporteurIdString ? parseInt(transporteurIdString, 10) : null;
    
      this.chauffeurService.getUsers().subscribe(data => {
        // Filtrer les utilisateurs par rôle "Chauffeur" et par transporteurId
        this.users = data.filter(user => 
          user.role === 'Chauffeur' && user.transporteurId === transporteurIdNumber 
        );
      });
    }

    getVehicules(): void {
      const userId = localStorage.getItem('idUser');
      this.vehiculeService.getVehicules().subscribe(
        (data: any[]) => {
          if (userId !== null) {
            // Convertir userId en entier seulement s'il n'est pas null
            const userIdNumber = parseInt(userId, 10);
            this.vehicules = data.filter(item => item.transporteurId === userIdNumber);
          } else {
            // Gérer le cas où userId est null, par exemple en assignant un tableau vide ou en affichant un message
            this.vehicules = [];
          }
        },
        error => {
          console.error('Erreur lors de la récupération des données :', error);
        }
      );
    }

  loadUserData(): void {
    const userId = localStorage.getItem('idUser');
    if (userId !== null) {
      this.userService.getUserById(userId).subscribe(
        (data) => {
          this.user = data;
        },
        (error) => {
          console.error('Error fetching user data', error);
        }
      );
    } else {
      console.error('User ID is not available in localStorage');
      // Vous pouvez rediriger l'utilisateur ou afficher un message d'erreur ici
    }
  }


  
  showUpdatePopup(): void {
    Swal.fire({
      title: 'Mettre à jour le profil',
      html: `
        <input id="firstName" class="swal2-input" placeholder="Prénom" value="${this.user.firstName}">
        <input id="lastName" class="swal2-input" placeholder="Nom" value="${this.user.lastName}">
        <input id="email" class="swal2-input" placeholder="Email" value="${this.user.email}">
        <input id="phoneNumber" class="swal2-input" placeholder="Téléphone" value="${this.user.phoneNumber}">
        <input id="address" class="swal2-input" placeholder="Adresse" value="${this.user.address}">
        <input id="city" class="swal2-input" placeholder="Ville" value="${this.user.city}">
        <input id="image" class="swal2-input" type="file" accept="image/*">
      `,
      showCancelButton: true,
      confirmButtonText: 'Mettre à jour',
      preConfirm: () => {
        const firstName = (document.getElementById('firstName') as HTMLInputElement).value;
        const lastName = (document.getElementById('lastName') as HTMLInputElement).value;
        const email = (document.getElementById('email') as HTMLInputElement).value;
        const phoneNumber = (document.getElementById('phoneNumber') as HTMLInputElement).value;
        const address = (document.getElementById('address') as HTMLInputElement).value;
        const city = (document.getElementById('city') as HTMLInputElement).value;
  
        if (!firstName || !lastName || !email || !phoneNumber || !address || !city) {
          Swal.showValidationMessage('Tous les champs doivent être remplis');
          return null;
        }
  
        return {
          firstName,
          lastName,
          email,
          phoneNumber,
          address,
          city,
          image: (document.getElementById('image') as HTMLInputElement).files?.[0] || null
        };
      }
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        const updatedUser = result.value;
        this.updateUserProfile(updatedUser);
      }
    });
  }
  

  updateUserProfile(updatedUser: any): void {
    this.userService.updateUser(this.user.id, updatedUser).subscribe(
      (response) => {
        this.user = response;
        Swal.fire('Succès', 'Profil mis à jour avec succès', 'success');
      },
      (error) => {
        console.error('Error updating user profile', error);
        Swal.fire('Erreur', 'Une erreur s\'est produite lors de la mise à jour du profil', 'error');
      }
    );
  }
}
